import { dispatch, getState } from '..';
import { EmployeeHelper, IEmployee, IPermission } from '../../@types/employee';
import DataActions from './actions';
import firebase from 'firebase/app';
import firebaseApp, { FirebaseService } from '../../services/firebaseService';
import lodash from 'lodash';
import generalFunctions from '../general/functions';
import httpService from '../../services/httpService';
import DivisionHelper, { IDivision } from '../../@types/model/masterData/division';
import { TransformerGroupHelper } from '../../@types/model/transformer/transformerGroup';
import TransformerUsageHelper from '../../@types/model/transformer/transformerUsage';
import TransformerAttributeHelper from '../../@types/model/transformer/transformerAttribute';
import CompanyHelper from '../../@types/model/masterData/company';
import HttpGeoServerService from '../../services/geoserverHttpService';
import { Area } from '../../@types/model/area';

export default class DataFunctions {
    private static EMPLOYEE_COLLECTION = 'employee';
    private static BOREHOLE_GROUNDWATERZONES = 'boreholeGroundwaterZones';

    private static employeeListener ?: () => void;
    private static divisionListener ?: () => void;
    private static groundwaterListener ?: () => void;
    private static transformerGroupListener ?: () => void;
    private static transformerUsageListener ?: () => void;
    private static transformerAttributesListener ?: () => void;
    private static companyListener ?: () => void;

    public static readonly setEmployeesLoading = (loading : boolean) => {
        dispatch(DataActions.dataSetLoadingEmployee(loading));
    };

    public static readonly setEmployees = (list : Array<IEmployee>) => {
        dispatch(DataActions.dataSetEmployees(list));
    };

    public static readonly getEmployees = async () => {
        if (DataFunctions.employeeListener) return;

        DataFunctions.setEmployeesLoading(true);
        DataFunctions.setEmployees([]);

        try {
            DataFunctions.employeeListener = EmployeeHelper
                .collection()
                .orderBy('Name')
                .onSnapshot((snapshot) => {
                    const dataState = getState().data;

                    const employees = dataState.employees.slice();

                    // "added" | "removed" | "modified"
                    snapshot.docChanges().forEach((f) => {
                        const employee = f.doc.data();

                        const index = lodash.findIndex(employees, n => n.Id === employee.Id);

                        switch (f.type) {
                            case 'added':
                                employees.push(employee);
                                break;
                            case 'modified':
                                employees.splice(index, 1, employee);
                                break;
                            case 'removed':
                                employees.splice(index, 1);
                                break;
                        }
                    });

                    DataFunctions.setEmployees(employees);

                    if (dataState.employee) {
                        const id = dataState.employee.Id;
                        DataFunctions.setEmployee(employees.find(n => n.Id === id));
                    }
                    DataFunctions.setEmployeesLoading(false);
                });

        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar('An error while loading employees.');
            DataFunctions.setEmployeesLoading(false);
        }
    };

    public static readonly setEmployee = (data ?: IEmployee) => {
        dispatch(DataActions.dataSetEmployee(data));
    };

    public static readonly deactivateEmployee = async (id : string) => {
        DataFunctions.setEmployeesLoading(true);

        try {
            await firebase
                .firestore()
                .collection(DataFunctions.EMPLOYEE_COLLECTION)
                .doc(id)
                .update({
                    IsActive: false,
                });
        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar('An error while deactivating employee.');
        }

        DataFunctions.setEmployeesLoading(false);
    };

    public static readonly saveEmployee = async (
        name : string,
        areas : Array<string>,
        damConstructionType : Array<number>,
        permissions : IPermission
    ) => {
        const oldEmployee = Object.assign({}, getState().data.employee);

        if (!name) return;

        DataFunctions.setEmployeesLoading(true);

        try {
            // Don't save ID to firestore
            const { Id, ...employee } = oldEmployee;

            employee.Name = name;
            employee.Areas = areas;
            employee.DamConstructionTypes = damConstructionType;
            employee.IsBoreholeAdmin = !!permissions.IsBoreholeAdmin;
            employee.IsBoreholeExport = !!permissions.IsBoreholeExport;
            employee.IsBoreholeCreateAdmin = !!permissions.IsBoreholeCreateAdmin;
            employee.IsBoreholeTester = !!permissions.IsBoreholeTester;
            employee.IsBoreholeUser = !!permissions.IsBoreholeUser;
            employee.IsBoreholeYieldAdjuster = !!permissions.IsBoreholeYieldAdjuster;
            employee.IsBoreholeFlowMeter = !!permissions.IsBoreholeFlowMeter;
            employee.IsEmployeeAdmin = !!permissions.IsEmployeeAdmin;
            employee.IsMottechAdmin = !!permissions.IsMottechAdmin;
            employee.IsMottechTester = !!permissions.IsMottechTester;
            employee.IsMottechUser = !!permissions.IsMottechUser;
            employee.IsRiverAdmin = !!permissions.IsRiverAdmin;
            employee.IsRiverTester = !!permissions.IsRiverTester;
            employee.IsRiverUser = !!permissions.IsRiverUser;
            employee.IsTransformerAdmin = !!permissions.IsTransformerAdmin;
            employee.IsTransformerLpuTester = !!permissions.IsTransformerLpuTester;
            employee.IsTransformerSpuTester = !!permissions.IsTransformerSpuTester;
            employee.IsTransformerLpuTariff = !!permissions.IsTransformerLpuTariff;
            employee.IsTransformerSpuTariff = !!permissions.IsTransformerSpuTariff;
            employee.IsTransformerUser = !!permissions.IsTransformerUser;
            employee.IsWeatherStationAdmin = !!permissions.IsWeatherStationAdmin;
            employee.IsWeatherStationTester = !!permissions.IsWeatherStationTester;
            employee.IsWeatherStationUser = !!permissions.IsWeatherStationUser;
            employee.IsMasterDataAdmin = !!permissions.IsMasterDataAdmin;
            employee.IsDivisionAdmin = !!permissions.IsDivisionAdmin;
            employee.IsBoreholeMasterDataAdmin = !!permissions.IsBoreholeMasterDataAdmin;
            employee.IsTransformerBudgetAdmin = !!permissions.IsTransformerBudgetAdmin;
            employee.IsTransformerTariffAdmin = !!permissions.IsTransformerTariffAdmin;
            employee.IsTransformerMasterDataAdmin = !!permissions.IsTransformerMasterDataAdmin;
            employee.IsDamAdmin = !!permissions.IsDamAdmin;
            employee.IsDamTester = !!permissions.IsDamTester;
            employee.IsDamRegReportUser = !!permissions.IsDamRegReportUser;
            employee.IsDamSafetyReportUser = !!permissions.IsDamSafetyReportUser;
            employee.IsDamUser = !!permissions.IsDamUser;
            employee.IsPumpingStationAdmin = !!permissions.IsPumpingStationAdmin;
            employee.IsPumpingStationCreateAdmin = !!permissions.IsPumpingStationCreateAdmin;
            employee.IsPumpingStationTester = !!permissions.IsPumpingStationTester;
            employee.IsPumpingStationUser = !!permissions.IsPumpingStationUser;
            employee.IsTransformerExport = permissions.IsTransformerExport ?? false;
            employee.IsWeatherExport = permissions.IsWeatherExport ?? false;
            employee.IsRiverExport = permissions.IsRiverExport ?? false;
            employee.IsMottechExport = permissions.IsMottechExport ?? false;
            employee.IsDamExport = permissions.IsDamExport ?? false;
            employee.IsPumpingStationExport = permissions.IsPumpingStationExport ?? false;
            employee.IsCompanyAdmin = permissions.IsCompanyAdmin ?? false;
            employee.IsTransformerMunicipalityTester = permissions.IsTransformerMunicipalityTester ?? false;
            employee.IsMunicipalityInvoice = permissions.IsMunicipalityInvoice ?? false;
            employee.IsSPSInvoice = permissions.IsSPSInvoice ?? false;
            employee.IsTransformerPrepaidTester = permissions.IsTransformerPrepaidTester ?? false;

            employee.IsFlowmeterAdmin = permissions.IsFlowmeterAdmin ?? false;
            employee.IsFlowmeterExport = permissions.IsFlowmeterExport ?? false;
            employee.IsFlowmeterCreateAdmin = permissions.IsFlowmeterCreateAdmin ?? false;
            employee.IsFlowmeterUser = permissions.IsFlowmeterUser ?? false;
            employee.IsFlowmeterTester = permissions.IsFlowmeterTester ?? false;

            employee.IsFillingPointAdmin = permissions.IsFillingPointAdmin ?? false;
            employee.IsFillingPointUser = permissions.IsFillingPointUser ?? false;
            employee.IsFillingPointTester = permissions.IsFillingPointTester ?? false;

            if (!(employee.CreatedOn instanceof firebase.firestore.Timestamp)) {
                employee.CreatedOn = firebase.firestore.Timestamp.now();
            }

            await firebase
                .firestore()
                .collection(DataFunctions.EMPLOYEE_COLLECTION)
                .doc(Id)
                .update(employee);
        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar(`An error while saving employee. ${ex}`);
        }

        DataFunctions.setEmployeesLoading(false);
    };

    public static readonly registerEmployee = async (employeeNumber : string, name : string) => {
        if (!employeeNumber) return;

        DataFunctions.setEmployeesLoading(true);

        let result = false;
        try {
            await httpService.registerEmployee(employeeNumber, name);

            const dataState = getState().data;

            const employees = dataState.employees.slice();

            const selectedEmployee = employees.find(n => n.EmployeeNumber === employeeNumber);

            DataFunctions.setEmployee(selectedEmployee);

            result = true;
        } catch (ex) {
            generalFunctions.generalShowError(ex, 'An error while creating employee');
        }

        DataFunctions.setEmployeesLoading(false);
        return result;
    };

    public static readonly setDivisionsLoading = (loading : boolean) => {
        dispatch(DataActions.dataSetLoadingDivisions(loading));
    };

    public static readonly getDivisions = async () => {
        if (DataFunctions.divisionListener) return;

        DataFunctions.setDivisionsLoading(true);
        dispatch(DataActions.dataSetDivisions([]));

        try {
            DataFunctions.divisionListener = DivisionHelper
                .listen()
                .onSnapshot((snapshot) => {
                    const dataState = getState().data;

                    const divisions = dataState.divisions.slice();

                    // "added" | "removed" | "modified"
                    snapshot.docChanges().forEach((f) => {
                        const division = f.doc.data();

                        switch (f.type) {
                            case 'added':
                                divisions.splice(f.newIndex, 0, division);
                                break;
                            case 'modified':
                                divisions.splice(f.newIndex, 1, division);
                                break;
                            case 'removed':
                                divisions.splice(f.newIndex, 1);
                                break;
                        }
                    });

                    dispatch(DataActions.dataSetDivisions(divisions));
                    DataFunctions.setDivisionsLoading(false);
                });

        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar('An error while loading divisions.');
            DataFunctions.setDivisionsLoading(false);
        }
    };

    public static readonly saveDivision = async (division : IDivision) => {

        const session = getState().auth.session;

        if (!session) return;

        DataFunctions.setDivisionsLoading(true);

        try {
            const save : IDivision = {
                ...division,

                UpdatedOn: firebase.firestore.Timestamp.now(),
                UpdatedBy: firebaseApp.firestore().collection(DataFunctions.EMPLOYEE_COLLECTION).doc(session.firebaseUser.uid),
                UpdatedByEmployee: session.firebaseUser.displayName ? session.firebaseUser.displayName : '',
                UpdatedByName: session.firebaseUser.displayName ? session.firebaseUser.displayName : '',
                IsActive: !!division.IsActive,
            };

            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (!division.CreatedBy) {
                division.CreatedOn = firebase.firestore.Timestamp.now();
                division.CreatedBy = firebaseApp.firestore().collection(DataFunctions.EMPLOYEE_COLLECTION).doc(session.firebaseUser.uid);
                division.CreatedByName = session.firebaseUser.displayName ? session.firebaseUser.displayName : '';
            }

            await DivisionHelper
                .save(save);

        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar(`An error while saving Division. ${ex}`);
        } finally {
            DataFunctions.setDivisionsLoading(false);
        }
    };

    public static readonly getGroundwaterZones = async () => {
        if (getState().data.loadingGroundwaterZones) return;
        if (DataFunctions.groundwaterListener) return;

        dispatch(DataActions.dataSetLoadingGroundwaterZones(true));
        dispatch(DataActions.dataSetGroundwaterZones([]));

        try {
            DataFunctions.groundwaterListener = firebase
                .firestore()
                .collection(DataFunctions.BOREHOLE_GROUNDWATERZONES)
                .onSnapshot((snapshot : firebase.firestore.QuerySnapshot) => {
                    const dataState = getState().data;

                    const zones = dataState.groundwaterZones.slice();

                    // "added" | "removed" | "modified"
                    snapshot.docChanges().forEach((f) => {
                        const zone = {
                            Code: f.doc.data()['Code'],
                            Name: f.doc.data()['Name'],
                        };

                        const index = lodash.findIndex(zones, n => n.Name === zone.Name);

                        switch (f.type) {
                            case 'added':
                                zones.push(zone);
                                break;
                            case 'modified':
                                zones.splice(index, 1, zone);
                                break;
                            case 'removed':
                                zones.splice(index, 1);
                                break;
                        }
                    });

                    dispatch(DataActions.dataSetGroundwaterZones(zones));
                    dispatch(DataActions.dataSetLoadingGroundwaterZones(false));
                });

        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar('An error while loading groundwater zones.');
            dispatch(DataActions.dataSetLoadingGroundwaterZones(false));
        }
    };

    public static readonly linkWithEmployeeNumber = async (employeeNumber : string, email : string) => {
        dispatch(DataActions.dataSetLoadingEmployee(true));

        let result = false;
        try {
            await httpService.linkEmployee(employeeNumber, email);
            result = true;
        } catch (ex) {
            generalFunctions.generalShowError(ex, 'An error while linking employee.');
        }

        dispatch(DataActions.dataSetLoadingEmployee(false));
        return result;
    };

    public static readonly unlinkWithEmployeeNumber = async (email : string) => {
        dispatch(DataActions.dataSetLoadingEmployee(true));

        let result = false;
        try {
            await httpService.unlinkEmployee(email);
            result = true;
        } catch (ex) {
            generalFunctions.generalShowError(ex, 'An error while unlinking employee.');
        }

        dispatch(DataActions.dataSetLoadingEmployee(false));
        return result;
    };

    public static readonly getAreas = async (refresh ?: boolean) => {

        const dataState = getState().data;

        if (dataState.isLoadingAreas) return;
        if (dataState.areas.length && !refresh) return;

        try {
            dispatch(DataActions.setLoadingAreas(true));

            const result = await HttpGeoServerService.getDepartments();

            const areas = result.data.features.map(n => new Area(n.properties));
            dispatch(DataActions.setAreas(areas));
        } catch (ex) {
            if (ex) {
                generalFunctions.generalShowErrorSnackbar('An error while loading areas.');
            }
        } finally {
            dispatch(DataActions.setLoadingAreas(false));
        }
    };

    public static readonly getDepartments = async (refresh ?: boolean) => {

        const dataState = getState().data;

        if (dataState.isLoadingDepartments) return;
        if (dataState.departments.length && !refresh) return;

        try {
            dispatch(DataActions.setLoadingDepartments(true));

            const result = await FirebaseService.getDepartments();

            dispatch(DataActions.setDepartments(result));
        } catch (ex) {
            if (ex) {
                generalFunctions.generalShowErrorSnackbar('An error while loading areas.');
            }
        } finally {
            dispatch(DataActions.setLoadingDepartments(false));
        }
    };

    public static readonly getTransformerGroups = async (refresh ?: boolean) => {

        const dataState = getState().data;

        if (dataState.isLoadingTransformerGroups) return;
        if (dataState.transformerGroups.length && !refresh) return;

        if (DataFunctions.transformerGroupListener) DataFunctions.transformerGroupListener();

        try {
            dispatch(DataActions.setLoadingTransformerGroups(true));
            DataFunctions.transformerGroupListener = TransformerGroupHelper.listen().onSnapshot((snapshot) => {
                const groups = getState().data.transformerGroups.slice();

                snapshot.docChanges().forEach((f) => {
                    const group = f.doc.data();
                    const index = lodash.findIndex(groups, n => n.id === f.doc.id);

                    switch (f.type) {
                        case 'added':
                            groups.push(group);
                            break;
                        case 'modified':
                            groups.splice(index, 1, group);
                            break;
                        case 'removed':
                            groups.splice(index, 1);
                            break;
                    }
                });

                dispatch(DataActions.setTransformerGroups(lodash.sortBy(groups, p => p.name)));
                dispatch(DataActions.setLoadingTransformerGroups(false));
            }, (error) => {
                generalFunctions.generalShowErrorSnackbar(error.message ? error.message : 'Error loading groups.');
                dispatch(DataActions.setLoadingTransformerGroups(false));
            });
        } catch (ex) {
            if (ex) {
                generalFunctions.generalShowErrorSnackbar('An error while loading groups.');
            }
            dispatch(DataActions.setLoadingTransformerGroups(false));
        }
    };
    
    public static readonly getTransformerUsages = async (refresh ?: boolean) => {

        const dataState = getState().data;

        if (dataState.isLoadingTransformerUsages) return;
        if (dataState.transformerUsages.length && !refresh) return;

        if (DataFunctions.transformerUsageListener) DataFunctions.transformerUsageListener();

        try {
            dispatch(DataActions.setLoadingTransformerUsages(true));
            DataFunctions.transformerUsageListener = TransformerUsageHelper.listen().onSnapshot((snapshot) => {
                const usages = getState().data.transformerUsages.slice();

                snapshot.docChanges().forEach((f) => {
                    const usage = f.doc.data();
                    const index = lodash.findIndex(usages, n => n.id === f.doc.id);

                    switch (f.type) {
                        case 'added':
                            usages.push(usage);
                            break;
                        case 'modified':
                            usages.splice(index, 1, usage);
                            break;
                        case 'removed':
                            usages.splice(index, 1);
                            break;
                    }
                });

                dispatch(DataActions.setTransformerUsages(lodash.sortBy(usages, p => parseInt(p.code.substr(2)))));
                dispatch(DataActions.setLoadingTransformerUsages(false));
            }, (error) => {
                generalFunctions.generalShowErrorSnackbar(error.message ? error.message : 'Error loading usages.');
                dispatch(DataActions.setLoadingTransformerUsages(false));
            });
        } catch (ex) {
            if (ex) {
                generalFunctions.generalShowErrorSnackbar('An error while loading usages.');
            }
            dispatch(DataActions.setLoadingTransformerUsages(false));
        }
    };

    public static readonly getTransformerAttributes = async (refresh ?: boolean) => {

        const dataState = getState().data;

        if (dataState.isLoadingTransformerAttributes) return;
        if (dataState.transformerAttributes.length && !refresh) return;

        if (DataFunctions.transformerAttributesListener) DataFunctions.transformerAttributesListener();

        try {
            dispatch(DataActions.setLoadingTransformerAttributes(true));
            DataFunctions.transformerAttributesListener = TransformerAttributeHelper.listen().onSnapshot((snapshot) => {
                const attributes = getState().data.transformerAttributes.slice();

                snapshot.docChanges().forEach((f) => {
                    const attribute = f.doc.data();
                    const index = lodash.findIndex(attributes, n => n.Id === f.doc.id);

                    switch (f.type) {
                        case 'added':
                            attributes.push(attribute);
                            break;
                        case 'modified':
                            attributes.splice(index, 1, attribute);
                            break;
                        case 'removed':
                            attributes.splice(index, 1);
                            break;
                    }
                });

                dispatch(DataActions.setTransformerAttributes(attributes));
                dispatch(DataActions.setLoadingTransformerAttributes(false));
            }, (error) => {
                generalFunctions.generalShowErrorSnackbar(error.message ? error.message : 'Error loading attributes.');
                dispatch(DataActions.setLoadingTransformerAttributes(false));
            });
        } catch (ex) {
            if (ex) {
                generalFunctions.generalShowErrorSnackbar('An error while loading attributes.');
            }
            dispatch(DataActions.setLoadingTransformerAttributes(false));
        }
    };

    public static readonly getCompanies = async (refresh ?: boolean) => {

        const dataState = getState().data;

        if (dataState.isLoadingCompanies) return;
        if (dataState.companies.length && !refresh) return;

        if (DataFunctions.companyListener) DataFunctions.companyListener();

        try {
            dispatch(DataActions.setLoadingCompany(true));
            DataFunctions.companyListener = CompanyHelper.listen().onSnapshot((snapshot) => {
                const items = getState().data.companies.slice();

                snapshot.docChanges().forEach((f) => {
                    const item = f.doc.data();
                    const index = lodash.findIndex(items, n => n.id === f.doc.id);

                    switch (f.type) {
                        case 'added':
                            items.push(item);
                            break;
                        case 'modified':
                            items.splice(index, 1, item);
                            break;
                        case 'removed':
                            items.splice(index, 1);
                            break;
                    }
                });

                dispatch(DataActions.setCompanies(items));
                dispatch(DataActions.setLoadingCompany(false));
            }, (error) => {
                generalFunctions.generalShowErrorSnackbar(error.message ? error.message : 'Error loading companies.');
                dispatch(DataActions.setLoadingCompany(false));
            });
        } catch (ex) {
            if (ex) {
                generalFunctions.generalShowErrorSnackbar('An error while loading companies.');
            }
            dispatch(DataActions.setLoadingCompany(false));
        }
    };
}
