import firebaseApp from '../services/firebaseService';

export interface IUserSession {
    employee : IEmployee;
    firebaseUser : firebase.User;
}

export interface IEmployee extends IPermission {
    Id : string;
    Name : string;
    LastSignIn ?: firebase.firestore.Timestamp;
    Email ?: string;
    EmployeeNumber ?: string;
    IsActive ?: boolean;
    CreatedOn ?: firebase.firestore.Timestamp;
    Areas : Array<string>;
    DamConstructionTypes : Array<number>;
}

export interface IPermission {
    [key : string] : boolean | string | firebase.firestore.Timestamp | Array<string> | Array<number> | undefined;
    IsBoreholeAdmin ?: boolean;
    IsBoreholeExport ?: boolean;
    IsBoreholeCreateAdmin ?: boolean;
    IsBoreholeUser ?: boolean;
    IsBoreholeTester ?: boolean;
    IsBoreholeYieldAdjuster ?: boolean;
    IsBoreholeFlowMeter ?: boolean;
    IsTransformerAdmin ?: boolean;
    IsTransformerUser ?: boolean;
    IsTransformerExport ?: boolean;
    IsTransformerLpuTester ?: boolean;
    IsTransformerSpuTester ?: boolean;
    IsTransformerLpuTariff ?: boolean;
    IsTransformerSpuTariff ?: boolean;
    IsWeatherStationAdmin ?: boolean;
    IsWeatherStationUser ?: boolean;
    IsWeatherStationTester ?: boolean;
    IsWeatherExport ?: boolean;
    IsRiverAdmin ?: boolean;
    IsRiverUser ?: boolean;
    IsRiverTester ?: boolean;
    IsRiverExport ?: boolean;
    IsMottechAdmin ?: boolean;
    IsMottechUser ?: boolean;
    IsMottechTester ?: boolean;
    IsMottechExport ?: boolean;
    IsEmployeeAdmin ?: boolean;
    IsMasterDataAdmin ?: boolean;
    IsDivisionAdmin ?: boolean;
    IsBoreholeMasterDataAdmin ?: boolean;
    IsTransformerTariffAdmin ?: boolean;
    IsTransformerBudgetAdmin ?: boolean;
    IsTransformerMasterDataAdmin ?: boolean;
    IsDamAdmin ?: boolean;
    IsDamUser ?: boolean;
    IsDamTester ?: boolean;
    IsDamRegReportUser ?: boolean;
    IsDamSafetyReportUser ?: boolean;
    IsDamExport ?: boolean;
    IsPumpingStationAdmin ?: boolean;
    IsPumpingStationCreateAdmin ?: boolean;
    IsPumpingStationUser ?: boolean;
    IsPumpingStationTester ?: boolean;
    IsPumpingStationExport ?: boolean;
    IsCompanyAdmin ?: boolean;
    IsTransformerMunicipalityTester ?: boolean;
    IsTransformerPrepaidTester ?: boolean;
    IsMunicipalityInvoice ?: boolean;
    IsSPSInvoice ?: boolean;

    IsFlowmeterAdmin ?: boolean;
    IsFlowmeterExport ?: boolean;
    IsFlowmeterCreateAdmin ?: boolean;
    IsFlowmeterUser ?: boolean;
    IsFlowmeterTester ?: boolean;
    
    IsFillingPointAdmin ?: boolean;
    IsFillingPointUser ?: boolean;
    IsFillingPointTester ?: boolean;
}

export interface IEmployeeRole {
    flag : string;
    label : string;
    children ?: Array<IEmployeeRole>;
    parentFlag ?: string;
}

const boreholeRoles : Array<IEmployeeRole> = [
    { parentFlag: 'IsBoreholeUser', flag: 'IsBoreholeYieldAdjuster', label: 'Yield Adjuster' },
    { parentFlag: 'IsBoreholeUser', flag: 'IsBoreholeTester', label: 'Tester' },
    { parentFlag: 'IsBoreholeUser', flag: 'IsBoreholeFlowMeter', label: 'Flow Meter' }];

const transformerRoles : Array<IEmployeeRole> = [{
    parentFlag: 'IsTransformerUser',
    flag: 'IsTransformerLpuTester',
    label: 'LPU Meter',
}, {
    parentFlag: 'IsTransformerUser',
    flag: 'IsTransformerLpuTariff',
    label: 'LPU Invoice',
}, {
    parentFlag: 'IsTransformerUser',
    flag: 'IsTransformerSpuTester',
    label: 'SPU Meter',
}, {
    parentFlag: 'IsTransformerUser',
    flag: 'IsTransformerSpuTariff',
    label: 'SPU Invoice',
}, {
    parentFlag: 'IsTransformerUser',
    flag: 'IsTransformerMunicipalityTester',
    label: 'Municipality Meter',
}, {
    parentFlag: 'IsTransformerUser',
    flag: 'IsMunicipalityInvoice',
    label: 'Municipality Invoice',
}, {
    parentFlag: 'IsTransformerUser',
    flag: 'IsSPSInvoice',
    label: 'SPS Invoice',
}, {
    parentFlag: 'IsTransformerUser',
    flag: 'IsTransformerPrepaidTester',
    label: 'Prepaid',
}];

const transformerTariffRoles : Array<IEmployeeRole> = [];

const weatherStationRoles = [{
    parentFlag: 'IsWeatherStationUser',
    flag: 'IsWeatherStationTester',
    label: 'Tester',
}];

const riverRoles = [{
    parentFlag: 'IsRiverUser',
    flag: 'IsRiverTester',
    label: 'Tester',
}];

const mottechRoles = [{
    parentFlag: 'IsMottechUser',
    flag: 'IsMottechTester',
    label: 'Tester',
}];

const masterDataRoles = [
    { parentFlag: 'IsMasterDataAdmin', flag: 'IsBoreholeMasterDataAdmin', label: 'Borehole' },
    { parentFlag: 'IsMasterDataAdmin', flag: 'IsTransformerMasterDataAdmin', label: 'Transformer' },
    { parentFlag: 'IsMasterDataAdmin', flag: 'IsDivisionAdmin', label: 'Division' },
    { parentFlag: 'IsMasterDataAdmin', flag: 'IsCompanyAdmin', label: 'Company' },
];

const damRoles = [
    { parentFlag: 'IsDamUser', flag: 'IsDamTester', label: 'Tester' },
    { parentFlag: 'IsDamUser', flag: 'IsDamRegReportUser', label: 'Reg. Report' },
    { parentFlag: 'IsDamUser', flag: 'IsDamSafetyReportUser', label: 'Safety Report' },
];

const pumpingStationRoles = [
    { parentFlag: 'IsPumpingStationUser', flag: 'IsPumpingStationTester', label: 'Tester' },
];

const flowmeterRoles = [
    { parentFlag: 'IsFlowmeterUser', flag: 'IsFlowmeterTester', label: 'Tester' },
];

const fillingStationRoles = [
    { parentFlag: 'IsFillingPointUser', flag: 'IsFillingPointTester', label: 'Tester' },
];

export const EMPLOYEE_ROLES : {[key : string] : IEmployeeRole} = {
    Boreholes: {
        flag: 'IsBoreholeAdmin',
        label: 'Admin',
        children: [{
            flag: 'IsBoreholeCreateAdmin',
            label: 'Create',
        }, {
            flag: 'IsBoreholeExport',
            label: 'Export',
        }, {
            flag: 'IsBoreholeUser',
            label: 'User',
            children: boreholeRoles,
        }],
    },
    Transformers: {
        flag: 'IsTransformerAdmin',
        label: 'Admin',
        children: [{
            flag: 'IsTransformerExport',
            label: 'Export',
        }, {
            flag: 'IsTransformerUser',
            label: 'User',
            children: transformerRoles,
        }, {
            flag: 'IsTransformerBudgetAdmin',
            label: 'Budget Management Admin',
            children: transformerTariffRoles,
        }, {
            flag: 'IsTransformerTariffAdmin',
            label: 'Tariff Management Admin',
            children: transformerTariffRoles,
        }],
    },
    'Weather Stations': {
        flag: 'IsWeatherStationAdmin',
        label: 'Admin',
        children: [{
            flag: 'IsWeatherExport',
            label: 'Export',
        }, {
            flag: 'IsWeatherStationUser',
            label: 'User',
            children: weatherStationRoles,
        }],
    },
    Rivers: {
        flag: 'IsRiverAdmin',
        label: 'Admin',
        children: [{
            flag: 'IsRiverExport',
            label: 'Export',
        }, {
            flag: 'IsRiverUser',
            label: 'User',
            children: riverRoles,
        }],
    },
    Mottech: {
        flag: 'IsMottechAdmin',
        label: 'Admin',
        children: [{
            flag: 'IsMottechExport',
            label: 'Export',
        }, {
            flag: 'IsMottechUser',
            label: 'User',
            children: mottechRoles,
        }],
    },
    Employee: {
        flag: 'IsEmployeeAdmin',
        label: 'Admin',
        children: [],
    },
    'Master Data': {
        flag: 'IsMasterDataAdmin',
        label: 'Admin',
        children: masterDataRoles,
    },
    Dam: {
        flag: 'IsDamAdmin',
        label: 'Admin',
        children: [{
            flag: 'IsDamExport',
            label: 'Export',
        }, {
            flag: 'IsDamUser',
            label: 'User',
            children: damRoles,
        }],
    },
    'Pumping Station': {
        flag: 'IsPumpingStationAdmin',
        label: 'Admin',
        children: [{
            flag: 'IsPumpingStationExport',
            label: 'Export',
        }, {
            flag: 'IsPumpingStationCreateAdmin',
            label: 'Create',
        }, {
            flag: 'IsPumpingStationUser',
            label: 'User',
            children: pumpingStationRoles,
        }],
    },
    'Flowmeter': {
        flag: 'IsFlowmeterAdmin',
        label: 'Admin',
        children: [{
            flag: 'IsFlowmeterExport',
            label: 'Export',
        }, {
            flag: 'IsFlowmeterCreateAdmin',
            label: 'Create',
        }, {
            flag: 'IsFlowmeterUser',
            label: 'User',
            children: flowmeterRoles,
        }],
    },
    'Filling Station': {
        flag: 'IsFillingPointAdmin',
        label: 'Admin',
        children: [{
            flag: 'IsFillingPointUser',
            label: 'User',
            children: fillingStationRoles,
        }],
    },
};

export class EmployeeHelper {
    public static readonly COLLECTION = 'employee';

    public static converter : firebase.firestore.FirestoreDataConverter<IEmployee> = {
        fromFirestore: EmployeeHelper.fromFirestore,
        toFirestore: EmployeeHelper.toObject,
    };

    private static fromFirestore(snapshot : firebase.firestore.DocumentSnapshot) : IEmployee {
        const data = snapshot.data() as IEmployee | null;

        if (!data) throw new Error('Document does not exist!');

        return {
            ...data,
            Id: snapshot.id,
            Areas: snapshot.data()?.Areas ?? [],
            DamConstructionTypes: snapshot.data()?.DamConstructionTypes ?? [],
        };
    }

    private static toObject(employee : IEmployee) {
        const { Id: _, ...result } = employee;
        return {
            ...result,
        };
    }

    public static doc(id : string) {
        return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter).doc(id);
    }

    public static collection() {
        return firebaseApp.firestore().collection(this.COLLECTION).withConverter(this.converter);
    }

    public static path(path : string) {
        return firebaseApp.firestore().doc(path).withConverter(this.converter);
    }
}
