import { firestore } from 'firebase/app';
import { BasicBaseHelper, IBasicBaseClass } from '../base';
import TransformerHelper, { ITransformer, TransformerMeterUoMType } from './transformer';
import firebaseApp, { FirebaseService } from '../../../services/firebaseService';
import { YupSchema } from '../../../services/helper/yupHelper';
import * as Yup from 'yup';
import { Moment } from 'moment';
import { FormikProps } from 'formik';
import { EmployeeHelper } from '../../employee';
import TransformerJournalHelper from './journal';
import { TransformerInvoiceHelper } from './transformerInvoice';
import Decimal from 'decimal.js';

export const waterBlocksTypes : [1, 2, 3, 4, 5, 6] = [1, 2, 3, 4, 5, 6];
export type waterBlock = typeof waterBlocksTypes[number];

export interface IMunicipalityInvoice extends IBasicBaseClass {
    accountNumber : string;
    group : string;
    monthDate : number;
    dueDate : number;

    electricity : IMunicipalityElectricityInvoice | null;
    water : IMunicipalityWaterInvoice | null;
    other : IMunicipalityOtherInvoice | null;

    transformerRef : string;
    transformer : ITransformer;

    totalExVat : number;
    vat : number;
    totalVat : number;
    calculatedVat : number;
    totalIncVat : number;
    credit : number;
    rebillTotal : number;

    reasonForRebill : string | null;

    reverted : boolean;
    relatedId : string | null;
    related : IMunicipalityInvoice | null;
    
    paid : boolean;

    // Path to user
    paidBy : string | null;
    paidByName : string | null;
    paidByEmployeeNumber : string | null;
    paidOn : number | null;

    journaled : boolean;

    journal : string | null;
    journalNr : string | null;

    // Path to user
    journaledBy : string | null;
    journaledByName : string | null;
    journaledByEmployeeNumber : string | null;
    journaledOn : number | null;
}

export interface IMunicipalityElectricityInvoice extends Record<string, unknown> {
    basicInterest : number;
    meteredInterest : number;
    fixedBasicCharge : number;
    demandCost : number;
    days : number;
    offPeakConsumptionKwh : number;
    standardConsumptionKwh : number;
    peakConsumptionKwh : number;
    offPeakCostReading : number;
    standardCostReading : number;
    peakCostReading : number;

    meters : Array<{
        meterNumber : string;
        unitOfMeasure : string | null;
    
        offPeakConsumptionKwh : number | null;
        standardConsumptionKwh : number;
        peakConsumptionKwh : number | null;
        offPeakCostReading : number | null;
        standardCostReading : number;
        peakCostReading : number | null;
    }>;

    usageZar : number;
    totalConsumption : number;
    total : number;
}

export interface IMunicipalityWaterInvoice extends Record<string, unknown> {
    waterAvailabilityCost : number;
    days : number | null;
    basicInterest : number;
    meteredInterest : number;
    
    blocks : Record<waterBlock, {
        consumption : number;
        cost : number;
    }>;

    totalConsumption : number;
    totalConsumptionCost : number;
    total : number;
}

export interface IMunicipalityOtherInvoice extends Record<string, unknown> {
    propertyRates : number;
    propertyRatesRebate : number;
    propertyInterest : number | null;
    yearlyPropertyTax : number;
    propertyRatesTotal : number;
    wasteDisposal : number;
    wasteDisposalInterest : number | null;
    sanitationSewerage : number;
    sanitationSewerageInterest : number | null;
    totalOtherCosts : number;
}

export interface IMunicipalityInvoiceFormValues extends Omit<IMunicipalityInvoice,
keyof IBasicBaseClass
| 'transformer'
| 'totalExVat'
| 'vat'
| 'totalVat'
| 'calculatedVat'
| 'totalIncVat'
| 'credit'
| 'rebillTotal'
| 'monthDate'
| 'dueDate'
| 'electricity'
| 'water'
| 'other'
| 'reverted'
| 'paid'
| 'paymentDueDate'
| 'paidBy'
| 'paidByName'
| 'paidByEmployeeNumber'
| 'paidOn'
| 'journaled'
| 'journal'
| 'journalNr'
| 'journaledBy'
| 'journaledByName'
| 'journaledByEmployeeNumber'
| 'journaledOn'
| 'relatedId'
| 'related'> {
    monthDate : Moment;
    dueDate : Moment;

    electricity : IMunicipalityElectricityInvoiceFormValues | null;
    water : IMunicipalityWaterInvoiceFormValues | null;
    other : IMunicipalityOtherInvoiceFormValues | null;

    transformer : ITransformer | null;

    totalExVat : number | null;
    vat : number | null;
    totalVat : number | null;
    calculatedVat : number | null;
    totalIncVat : number | null;
    credit : number | null;
    rebillTotal : number | null;
}

export interface IMunicipalityElectricityInvoiceMeterReadingFormValues {
    meterNumber : string;
    unitOfMeasure : TransformerMeterUoMType | '-';

    offPeakConsumptionKwh : number | null;
    standardConsumptionKwh : number | null;
    peakConsumptionKwh : number | null;
    offPeakCostReading : number | null;
    standardCostReading : number | null;
    peakCostReading : number | null;
}
export interface IMunicipalityElectricityInvoiceFormValues {
    basicInterest : number | null;
    meteredInterest : number | null;
    fixedBasicCharge : number | null;
    demandCost : number | null;
    days : number | null;

    meters : Array<IMunicipalityElectricityInvoiceMeterReadingFormValues>;

    usageZar : number;
    totalConsumption : number;
    total : number;
}

export interface IMunicipalityWaterInvoiceFormValues {
    waterAvailabilityCost : number | null;
    days : number | null;
    basicInterest : number | null;
    meteredInterest : number | null;
    
    blocks : Record<waterBlock, {
        consumption : number | null;
        cost : number | null;
    }>;

    totalConsumption : number;
    totalConsumptionCost : number;
    total : number;
}

export interface IMunicipalityOtherInvoiceFormValues {
    propertyRates : number | null;
    propertyInterest : number | null;
    propertyRatesRebate : number | null;
    yearlyPropertyTax : number | null;
    propertyRatesTotal : number;
    wasteDisposal : number | null;
    wasteDisposalInterest : number | null;
    sanitationSewerage : number | null;
    sanitationSewerageInterest : number | null;
    totalOtherCosts : number;
}

type YupShape = Record<keyof IMunicipalityInvoiceFormValues, YupSchema>;
type YupElectricityInvoiceShape = Record<keyof IMunicipalityElectricityInvoiceFormValues, YupSchema>;
type YupElectricityInvoiceMeterReadingShape = Record<keyof IMunicipalityElectricityInvoiceMeterReadingFormValues, YupSchema>;
type YupWaterBlockShape = Record<keyof {
    consumption : number | null;
    cost : number | null;
}, YupSchema>;
type YupWaterInvoiceShape = Record<keyof IMunicipalityWaterInvoiceFormValues, YupSchema>;
type YupOtherInvoiceShape = Record<keyof IMunicipalityOtherInvoiceFormValues, YupSchema>;

export class MunicipalityInvoiceHelper extends BasicBaseHelper {
    public static readonly COLLECTION_NAME = 'municipality_invoices';

    private static converter : firebase.firestore.FirestoreDataConverter<IMunicipalityInvoice> = {
        fromFirestore: (snapshot) => {
            return MunicipalityInvoiceHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : IMunicipalityInvoice) : firebase.firestore.DocumentData => {
            return MunicipalityInvoiceHelper.toFirestoreDocument(data);
        },
    };

    public static fromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot) : IMunicipalityInvoice {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!snapshot.exists || !result || !data) throw new Error(`Document does not exist! ${snapshot.id}`);
        return {
            ...data as IMunicipalityInvoice,
            ...result,
            dueDate: (data['dueDate'] as firestore.Timestamp).toMillis(),
            monthDate: (data['monthDate'] as firestore.Timestamp).toMillis(),
            transformerRef: (data['transformerRef'] as firestore.DocumentReference).id,
            transformer: TransformerHelper.fromDocument(data.transformer, data.transformerRef),
            paid: !!data.paid,
            paidBy: data.paidBy?.path ?? null,
            paidByName: data.paidByName ?? null,
            paidByEmployeeNumber: data.paidByEmployeeNumber ?? null,
            paidOn: data.paidOn?.toMillis() ?? null,
            journal: !data.journal ? null : (data.journal as firestore.DocumentReference).id,
            journaled: !!data.journaled,
            journalNr: data.journalNr ?? null,
            journaledBy: data.journaledBy?.path ?? null,
            journaledByName: data.journaledByName ?? null,
            journaledByEmployeeNumber: data.journaledByEmployeeNumber ?? null,
            journaledOn: data.journaledOn?.toMillis() ?? null,
            relatedId: !data.relatedId ? null : (data.relatedId as firestore.DocumentReference).path,
            related: data.related ?? null,
            reverted: !!data.reverted,
        };
    }

    public static toFirestoreDocument(data : IMunicipalityInvoice) {
        const result = super.toFirestore(data);
        const { id: _id, ...rest } = data;

        return {
            ...rest,
            ...result,
            dueDate: firestore.Timestamp.fromMillis(data.dueDate),
            monthDate: firestore.Timestamp.fromMillis(data.monthDate),
            transformerRef: TransformerHelper.doc(data.transformerRef),
            transformer: TransformerHelper.toFirestore(data.transformer, true),
            paid: !!data.paid,
            paidBy: !data.paidBy ? null : EmployeeHelper.path(data.paidBy),
            paidByName: data.paidByName ?? null,
            paidByEmployeeNumber: data.paidByEmployeeNumber ?? null,
            paidOn: !data.paidOn ? null : firestore.Timestamp.fromMillis(data.paidOn),
            journal: !data.journal ? null : TransformerJournalHelper.doc(data.journal),
            journaled: !!data.journaled,
            journalNr: data.journalNr ?? null,
            journaledBy: !data.journaledBy ? null : EmployeeHelper.path(data.journaledBy),
            journaledByName: data.journaledByName ?? null,
            journaledByEmployeeNumber: data.journaledByEmployeeNumber ?? null,
            journaledOn: !data.journaledOn ? null : firestore.Timestamp.fromMillis(data.journaledOn),
            related: !data.related ? null : data.related,
            relatedId: !data.related ? null : TransformerInvoiceHelper.doc(data.related.id),
            reverted: !!data.reverted,
        };
    }

    public static collection() {
        return firebaseApp.firestore().collection(MunicipalityInvoiceHelper.COLLECTION_NAME).withConverter(this.converter);
    }

    public static doc(id ?: string) {
        if (!id) {
            return this.collection().doc();
        }
        return this.collection().doc(id);
    }

    private static formElectricityMeterSchema = {
        meterNumber: Yup.string().required('Required'),
        unitOfMeasure: Yup.string().required('Required'),
        standardConsumptionKwh: Yup.number().nullable().required('Required'),
        standardCostReading: Yup.number().nullable().required('Required'),
    };

    private static formElectricitySchema : YupElectricityInvoiceShape = {
        basicInterest: Yup.number().nullable().optional(),
        meteredInterest: Yup.number().nullable().optional(),
        fixedBasicCharge: Yup.number().nullable().optional(),
        demandCost: Yup.number().nullable().optional(),
        days: Yup.number().nullable().optional(),
        meters: Yup.array<YupElectricityInvoiceMeterReadingShape>(Yup.object({
            ...MunicipalityInvoiceHelper.formElectricityMeterSchema,
            offPeakConsumptionKwh: Yup.number().nullable().optional(),
            peakConsumptionKwh: Yup.number().nullable().optional(),
            offPeakCostReading: Yup.number().nullable().optional(),
            peakCostReading: Yup.number().nullable().optional(),
        })).required(),
        usageZar: Yup.number().required('Required'),
        totalConsumption: Yup.number().required('Required'),
        total: Yup.number().required('Required'),
    };

    private static formWaterBlock : YupWaterBlockShape = {
        consumption: Yup.number().nullable().optional(),
        cost: Yup.number().nullable().optional(),
    };

    private static formWaterSchema : YupWaterInvoiceShape = {
        waterAvailabilityCost: Yup.number().nullable().optional(),
        days: Yup.number().nullable().optional(),
        basicInterest: Yup.number().nullable().optional(),
        meteredInterest: Yup.number().nullable().optional(),

        blocks: Yup.object(waterBlocksTypes.reduce((current, waterBlockType) => ({
            ...current,
            [waterBlockType]: Yup.object(MunicipalityInvoiceHelper.formWaterBlock),
        }), {})).required(),

        totalConsumption: Yup.number().required('Required'),
        totalConsumptionCost: Yup.number().required('Required'),
        total: Yup.number().required('Required'),
    };

    private static formOtherSchema : YupOtherInvoiceShape = {
        propertyRates: Yup.number().nullable().optional(),
        propertyRatesRebate: Yup.number().nullable().optional(),
        propertyInterest: Yup.number().nullable().optional(),
        yearlyPropertyTax: Yup.number().nullable().optional(),
        propertyRatesTotal: Yup.number().required('Required'),
        wasteDisposal: Yup.number().nullable().optional(),
        wasteDisposalInterest: Yup.number().nullable().optional(),
        sanitationSewerage: Yup.number().nullable().optional(),
        sanitationSewerageInterest: Yup.number().nullable().optional(),
        totalOtherCosts: Yup.number().required('Required'),
    };

    public static formSchema = () => Yup.object<YupShape>({
        monthDate: Yup.date().moment().required('Required'),
        dueDate: Yup.date().moment().required('Required'),
        accountNumber: Yup.string().required('Required'),
        group: Yup.string().required('Required'),
        totalExVat: Yup.number().nullable().required('Required'),
        vat: Yup.number().nullable().required('Required'),
        totalVat: Yup.number().nullable().required('Required'),
        calculatedVat: Yup.number().nullable().required('Required'),
        totalIncVat: Yup.number().nullable().required('Required'),
        credit: Yup.number().nullable().optional(),
        rebillTotal: Yup.number().nullable().optional(),
        reasonForRebill: Yup.string().when('rebillTotal', {
            is: (rebillTotal : number | null) => rebillTotal && rebillTotal > 0,
            then: Yup.string().nullable().required('Required'),
            otherwise: Yup.string().nullable().optional(),
        }),
        transformer: Yup.object().nullable().required('Required'),
        transformerRef: Yup.string().required('Required'),
        electricity: Yup.object(MunicipalityInvoiceHelper.formElectricitySchema).nullable().optional(),
        water: Yup.object(MunicipalityInvoiceHelper.formWaterSchema).nullable().optional(),
        other: Yup.object(MunicipalityInvoiceHelper.formOtherSchema).nullable().optional(),
    });

    public static calculateTotals = (form : FormikProps<IMunicipalityInvoiceFormValues>) => {
        let totalWaterConsumptionCost = new Decimal(0);
        let totalElectricityCost = new Decimal(0);
        let totalOtherCost = new Decimal(0);

        if (form.values.water) {
            const totalWaterConsumption = waterBlocksTypes.reduce((value, waterBlock) => (
                value.add(Number(form.values.water?.blocks[waterBlock].consumption ?? 0))
            ), new Decimal(0));
            totalWaterConsumptionCost = waterBlocksTypes.reduce((value, waterBlock) => (
                value.add(Number(form.values.water?.blocks[waterBlock].cost ?? 0))
            ), new Decimal(0));

            totalWaterConsumptionCost = totalWaterConsumptionCost.add(Number(form.values.water.waterAvailabilityCost));

            form.setFieldValue('water.totalConsumption', totalWaterConsumption.toNumber());
            form.setFieldValue('water.totalConsumptionCost', totalWaterConsumptionCost.toNumber());

            const total = totalWaterConsumptionCost
                .add(Number(form.values.water.waterAvailabilityCost ?? 0));

            form.setFieldValue('water.total', total.toNumber());
        }

        if (form.values.electricity) {
            const totalElectricityUsage = form.values.electricity.meters
                .filter(x => x.unitOfMeasure !== 'kVa')
                .reduce((value, meter) => (
                    value
                        .add(Number(meter.peakConsumptionKwh ?? 0))
                        .add(Number(meter.offPeakConsumptionKwh ?? 0))
                        .add(Number(meter.standardConsumptionKwh ?? 0))
                    
                ), new Decimal(0));

            const usageZar = form.values.electricity.meters
                .reduce((value, meter) => (
                    value
                        .add(Number(meter.standardCostReading ?? 0))
                        .add(Number(meter.peakCostReading ?? 0))
                        .add(Number(meter.offPeakCostReading ?? 0))
                    
                ), new Decimal(0));

            form.setFieldValue('electricity.usageZar', usageZar.toNumber());
            form.setFieldValue('electricity.totalConsumption', totalElectricityUsage.toNumber());

            totalElectricityCost = totalElectricityCost
                .add(usageZar)
                .add(Number(form.values.electricity.fixedBasicCharge ?? 0))
                .add(Number(form.values.electricity.demandCost ?? 0));

            form.setFieldValue('electricity.total', totalElectricityCost.toNumber());
        }

        let propertyRatesTotal = new Decimal(0);
        if (form.values.other) {
            propertyRatesTotal =
                propertyRatesTotal.add(Number(form.values.other.propertyRates ?? 0))
                    .add(Number(form.values.other.yearlyPropertyTax ?? 0))
                    .sub(Number(form.values.other.propertyRatesRebate ?? 0));

            totalOtherCost = totalOtherCost
                .add(Number(form.values.other.sanitationSewerage ?? 0))
                .add(Number(form.values.other.wasteDisposal ?? 0));

            form.setFieldValue('other.propertyRatesTotal', propertyRatesTotal.toNumber());
            form.setFieldValue('other.totalOtherCosts', totalOtherCost.toNumber());
        }

        let totalExVat = totalWaterConsumptionCost
            .add(totalElectricityCost)
            .add(totalOtherCost)
            .sub(Number(form.values.credit ?? 0));

        if (totalExVat.lessThanOrEqualTo(0)) {
            totalExVat = new Decimal(0);
            
            form.setFieldValue('totalVat', 0);
            form.setFieldValue('calculatedVat', 0);
            form.setFieldValue('totalIncVat', 0);
        } else {
            const vat = new Decimal(form.values.vat ?? FirebaseService.getVat());
            const totalVat = totalExVat.mul(vat);
            const totalIncVat = totalExVat.add(totalVat).add(propertyRatesTotal)
                .add(Number(form.values.electricity?.basicInterest ?? 0))
                .add(Number(form.values.electricity?.meteredInterest ?? 0))
                .add(Number(form.values.water?.basicInterest ?? 0))
                .add(Number(form.values.water?.meteredInterest ?? 0))
                .add(Number(form.values.other?.wasteDisposalInterest ?? 0))
                .add(Number(form.values.other?.propertyInterest ?? 0))
                .add(Number(form.values.other?.sanitationSewerageInterest ?? 0));
            
            form.setFieldValue('vat', vat.toNumber());

            if (!form.getFieldMeta('totalVat').touched) {
                form.setFieldValue('totalVat', totalVat.toNumber());
            }
            
            form.setFieldValue('calculatedVat', totalVat.toNumber());
            form.setFieldValue('totalIncVat', totalIncVat.toNumber());
        }

        form.setFieldValue('totalExVat', totalExVat.toNumber());
    };

    public static delete(data : IMunicipalityInvoice) {
        return firebaseApp.firestore().runTransaction(async (transaction) => {
            const doc = await transaction.get(MunicipalityInvoiceHelper.doc(data.id));

            transaction.delete(doc.ref);
        });
    }
}