import { BasicBaseHelper, IBasicBaseClass } from '../base';
import { firestore } from 'firebase/app';
import firebaseApp from '../../../services/firebaseService';
import TransformerHelper, { ITransformer } from './transformer';
import TransformerCommentHelper from './transformerComment';

export interface ITransformerGuarantee extends IBasicBaseClass {
    transformerRef : string; // Transformer Id
    date : number;

    cash : number | null;
    interest : number | null;
    nonCashDeposit : number | null;
    bank : string | null;
    guaranteeNumber : string | null;
    guaranteeAmount : number | null;
    blanket : string | null;
    attention : string | null;

    comment : string;
}

export default class TransformerGuaranteeHelper extends BasicBaseHelper {
    public static readonly COLLECTION_NAME = 'transformer_guarantee';

    private static converter : firebase.firestore.FirestoreDataConverter<ITransformerGuarantee> = {
        fromFirestore: (snapshot) => {
            return TransformerGuaranteeHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : ITransformerGuarantee) : firebase.firestore.DocumentData => {
            return TransformerGuaranteeHelper.toFirestoreDocument(data);
        },
    };

    public static fromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot) : ITransformerGuarantee {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!snapshot.exists || !result || !data) throw new Error(`Document does not exist! ${snapshot.id}`);
        return {
            ...data as ITransformerGuarantee,
            ...result,
            transformerRef: (data['transformerRef'] as firestore.DocumentReference).id,
            date: (data['date'] as firestore.Timestamp).toMillis(),
        };
    }

    public static toFirestoreDocument(data : ITransformerGuarantee) {
        const result = super.toFirestore(data);
        const { id:_id, ...rest } = data;

        return {
            ...rest,
            ...result,
            transformerRef: TransformerHelper.doc(data.transformerRef),
            date: firestore.Timestamp.fromMillis(data.date),
        };
    }
    
    public static collection(ref : firebase.firestore.DocumentReference<ITransformer>) {
        return ref
            .collection(TransformerGuaranteeHelper.COLLECTION_NAME)
            .withConverter(TransformerGuaranteeHelper.converter);
    }

    public static save(data : ITransformerGuarantee) {
        return firebaseApp.firestore().runTransaction(async (transaction) => {
            const doc = await transaction.get(TransformerGuaranteeHelper.doc(TransformerHelper.doc(data.transformerRef), data.id));

            if (data.comment) {
                const commentDoc = await transaction.get(TransformerCommentHelper.doc(TransformerHelper.doc(data.transformerRef), doc.id));

                const comment = commentDoc.data();
                
                transaction.set(commentDoc.ref, {
                    id: data.id,
                    comment: data.comment,
                    type: 'guarantee',
                    date: data.date,
                    transformerRef: data.transformerRef,
                    isWeb: data.isWeb,
                    createdBy: comment?.createdBy ?? data.createdBy,
                    createdByEmployee: comment?.createdByEmployee ?? data.createdByEmployee,
                    createdByName: comment?.createdByName ?? data.createdByName,
                    createdOn: comment?.createdOn ?? data.createdOn,
                    updatedBy: data.updatedBy,
                    updatedByEmployee: data.updatedByEmployee,
                    updatedByName: data.updatedByName,
                    updatedOn: data.updatedOn,
                });
            }

            transaction.set(doc.ref, data, {
                merge: true,
            });
        });
    }

    public static doc(ref : firebase.firestore.DocumentReference<ITransformer>, id ?: string) {
        if (!id) {
            return this.collection(ref).withConverter(TransformerGuaranteeHelper.converter).doc();
        }

        return this.collection(ref).withConverter(TransformerGuaranteeHelper.converter).doc(id);
    }

    public static delete(data : ITransformerGuarantee) {
        return firebaseApp.firestore().runTransaction(async (transaction) => {
            const doc = await transaction.get(TransformerGuaranteeHelper.doc(TransformerHelper.doc(data.transformerRef), data.id));
            const commentDoc = await transaction.get(TransformerCommentHelper.doc(TransformerHelper.doc(data.transformerRef), doc.id));

            transaction.delete(doc.ref);
            transaction.delete(commentDoc.ref);
        });
    }
}