import * as React from 'react';

import Drawer from '@material-ui/core/Drawer';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
import { IRootState, IAuthState } from '../../@types/redux';
import authFunctions from '../../store/auth/functions';
import SideMenuItem from '../customComponents/nav/SideMenuItem';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import dataFunctions from '../../store/data/functions';
import navFunctions from '../../store/nav/functions';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

interface INavDrawerProps {
    openLeft : boolean;
    openRight : boolean;
    toggleDrawer : () => void;
    auth : IAuthState;
}

class NavDrawer extends React.Component<INavDrawerProps> {
    constructor(props : INavDrawerProps) {
        super(props);
    }

    private readonly logout = () => {
        this.props.toggleDrawer();
        authFunctions.firebaseSignOut();
    };

    private readonly onBoreholeListNavClick = () => {
        navFunctions.navPath('/borehole/list');
        this.props.toggleDrawer();
    };

    private readonly onBoreholeMapNavClick = () => {
        navFunctions.navPath('/borehole/map');
        this.props.toggleDrawer();
    };

    private readonly onBoreholeRuntimesOverrideNavClick = () => {
        navFunctions.navPath('/borehole/runtimesOverride');
        this.props.toggleDrawer();
    };

    private readonly onTransformerListNavClick = () => {
        navFunctions.navPath('/transformer/list');
        this.props.toggleDrawer();
    };

    private readonly onTransformerMapNavClick = () => {
        navFunctions.navPath('/transformer/map');
        this.props.toggleDrawer();
    };

    private readonly onTransformerAccountNavClick = () => {
        navFunctions.navPath('/transformer/accounts');
        this.props.toggleDrawer();
    };

    private readonly onWeatherListNavClick = () => {
        navFunctions.navPath('/weather/list');
        this.props.toggleDrawer();
    };

    private readonly onWeatherMapNavClick = () => {
        navFunctions.navPath('/weather/map');
        this.props.toggleDrawer();
    };

    private readonly onMottechListNavClick = () => {
        navFunctions.navPath('/mottech/list');
        this.props.toggleDrawer();
    };

    private readonly onMottechMapNavClick = () => {
        navFunctions.navPath('/mottech/map');
        this.props.toggleDrawer();
    };

    private readonly onRiverListNavClick = () => {
        navFunctions.navPath('/river/list');
        this.props.toggleDrawer();
    };

    private readonly onRiverMapNavClick = () => {
        navFunctions.navPath('/river/map');
        this.props.toggleDrawer();
    };

    private readonly onEmployeeNavClick = () => {
        navFunctions.navPath('/employee');
        dataFunctions.setEmployee();
        this.props.toggleDrawer();
    };

    private readonly onDivionNavClick = () => {
        navFunctions.navPath('/division');
        this.props.toggleDrawer();
    };

    private readonly onCompanyNavClick = () => {
        navFunctions.navPath('/masterData/company');
        this.props.toggleDrawer();
    };

    private readonly onPumpsNavClick = () => {
        navFunctions.navPath('/masterData/pumps');
        this.props.toggleDrawer();
    };

    private readonly onMotorsNavClick = () => {
        navFunctions.navPath('/masterData/motors');
        this.props.toggleDrawer();
    };

    private readonly onBoreholeIssuesNavClick = () => {
        navFunctions.navPath('/masterData/boreholeIssues');
        this.props.toggleDrawer();
    };

    private readonly onMotorSizesNavClick = () => {
        navFunctions.navPath('/masterData/motorSizes');
        this.props.toggleDrawer();
    };

    private readonly onLPUAdminNavClick = () => {
        navFunctions.navPath('/transformer/tariffs', 'type=lpu');
        this.props.toggleDrawer();
    };

    private readonly onSPUAdminNavClick = () => {
        navFunctions.navPath('/transformer/tariffs', 'type=spu');
        this.props.toggleDrawer();
    };

    private readonly onBudgetNavClick = (event : React.MouseEvent<HTMLDivElement>) => {
        navFunctions.navPath('/transformer/budget', `type=${event.currentTarget.id}`);
        this.props.toggleDrawer();
    };

    private readonly onBudgetCSVNavClick = () => {
        navFunctions.navPath('/transformer/budget/csv');
        this.props.toggleDrawer();
    };

    private readonly onSPUMeterNavClick = () => {
        navFunctions.navPath('/transformer/capturing/spu/meter');
        this.props.toggleDrawer();
    };

    private readonly onMunicipalityMeterNavClick = () => {
        navFunctions.navPath('/transformer/capturing/municipality/meter');
        this.props.toggleDrawer();
    };

    private readonly onMunicipalityInvoiceNavClick = () => {
        navFunctions.navPath('/transformer/capturing/municipality/invoice');
        this.props.toggleDrawer();
    };

    private readonly onSPSInvoiceNavClick = () => {
        navFunctions.navPath('/transformer/capturing/sps/invoice');
        this.props.toggleDrawer();
    };

    private readonly onPrepaidNavClick = () => {
        navFunctions.navPath('/transformer/capturing/prepaid/meter');
        this.props.toggleDrawer();
    };

    private readonly onLPUMeterNavClick = () => {
        navFunctions.navPath('/transformer/capturing/lpu/meter');
        this.props.toggleDrawer();
    };

    private readonly onLPUCapturingNavClick = () => {
        navFunctions.navPath('/transformer/capturing', 'type=lpu');
        this.props.toggleDrawer();
    };

    private readonly onSPUCapturingNavClick = () => {
        navFunctions.navPath('/transformer/capturing', 'type=spu');
        this.props.toggleDrawer();
    };

    private readonly onTransformerGroupNavClick = () => {
        navFunctions.navPath('/masterData/transformer/group');
        this.props.toggleDrawer();
    };

    private readonly onTransformerUsageNavClick = () => {
        navFunctions.navPath('/masterData/transformer/usage');
        this.props.toggleDrawer();
    };

    private readonly onTransformerJournalsNavClick = () => {
        navFunctions.navPath('/transformer/journals');
        this.props.toggleDrawer();
    };

    private readonly onDamListNavClick = () => {
        navFunctions.navPath('/dam/list');
        this.props.toggleDrawer();
    };

    private readonly onDamMapNavClick = () => {
        navFunctions.navPath('/dam/map');
        this.props.toggleDrawer();
    };

    private readonly onPumpingStationListNavClick = () => {
        navFunctions.navPath('/pumpingStation/list');
        this.props.toggleDrawer();
    };

    private readonly onPumpingStationMapNavClick = () => {
        navFunctions.navPath('/pumpingStation/map');
        this.props.toggleDrawer();
    };

    private readonly onPumpingStationMasterDataNavClick = (event : React.MouseEvent) => {
        navFunctions.navPath(`/masterData/pumpingStation/${event.currentTarget.id}`);
        this.props.toggleDrawer();
    };

    private readonly onFlowmeterListNavClick = () => {
        navFunctions.navPath('/flowmeter/list');
        this.props.toggleDrawer();
    };

    private readonly onFlowmeterMapNavClick = () => {
        navFunctions.navPath('/flowmeter/map');
        this.props.toggleDrawer();
    };

    private readonly onFillingStationListNavClick = () => {
        navFunctions.navPath('/chemical_filling_point/list');
        this.props.toggleDrawer();
    };

    private readonly onFillingStationMapNavClick = () => {
        navFunctions.navPath('/chemical_filling_point/map');
        this.props.toggleDrawer();
    };

    public render() {
        const { auth } = this.props;
        return (
            <div>
                <Drawer variant='temporary' anchor='left' open={this.props.openLeft} onClose={this.props.toggleDrawer}>
                    <AppBar position='static'>
                        <Toolbar variant='dense'>
                            {
                                auth.isLoggedIn &&
                                auth.session &&
                                <IconButton color={'inherit'} className={'mr20'} onClick={this.props.toggleDrawer}>
                                    <Icon>menu</Icon>
                                </IconButton>
                            }
                            <img height={60} src={`${ASSET_BASE}/assets/images/logo.png`} className={'mr10'} />
                            <Typography color='inherit' variant='h6'>
                                Technical
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <div className={'fdc flx1 w300 bcg2 oa drawer'}>
                        {
                            auth.session?.employee.IsBoreholeUser &&
                            <div>
                                <SideMenuItem key={'borehole_nav_header'} icon={<img className={'h30 w30'} src={`${ASSET_BASE}/assets/images/icons/borehole.svg`} />}
                                    title={'Boreholes'}>
                                    <ListItem button key={'borehole_list_nav'} onClick={this.onBoreholeListNavClick}>
                                        <ListItemIcon>
                                            <Icon>list</Icon>
                                        </ListItemIcon>
                                        <ListItemText primary={'LIST'} />
                                    </ListItem>
                                    <ListItem button key={'borehole_map_nav'} onClick={this.onBoreholeMapNavClick}>
                                        <ListItemIcon>
                                            <Icon>map</Icon>
                                        </ListItemIcon>
                                        <ListItemText primary={'MAP'} />
                                    </ListItem>
                                    <ListItem button key={'borehole_runtimes_override_nav'} onClick={this.onBoreholeRuntimesOverrideNavClick}>
                                        <ListItemIcon>
                                            <Icon><img className='pl2' height={24} src={`${ASSET_BASE}/assets/images/icons/timer.svg`}/></Icon>
                                        </ListItemIcon>
                                        <ListItemText primary={'RUNTIMES OVERRIDE'} />
                                    </ListItem>
                                </SideMenuItem>
                            </div>
                        }
                        {
                            auth.session?.employee.IsFillingPointUser &&
                            <div>
                                <SideMenuItem key={'chemical_filling_point_nav_header'} icon={<img className={'h30 w30'} src={`${ASSET_BASE}/assets/images/icons/chemical_filling_point.svg`} />}
                                    title={'Chemical Filling Point'}>
                                    <ListItem button key={'chemical_filling_point_list_nav'} onClick={this.onFillingStationListNavClick}>
                                        <ListItemIcon>
                                            <Icon>list</Icon>
                                        </ListItemIcon>
                                        <ListItemText primary={'LIST'} />
                                    </ListItem>
                                    <ListItem button key={'chemical_filling_point_map_nav'} onClick={this.onFillingStationMapNavClick}>
                                        <ListItemIcon>
                                            <Icon>map</Icon>
                                        </ListItemIcon>
                                        <ListItemText primary={'MAP'} />
                                    </ListItem>
                                </SideMenuItem>
                            </div>
                        }
                        {
                            auth.session?.employee.IsDamUser &&
                            <div>
                                <SideMenuItem key={'dam_nav_header'} icon={<img className={'h30 w30'} src={`${ASSET_BASE}/assets/images/icons/dam.svg`} />}
                                    title={'Dams'}>
                                    <ListItem button key={'dam_list_nav'} onClick={this.onDamListNavClick}>
                                        <ListItemIcon>
                                            <Icon>list</Icon>
                                        </ListItemIcon>
                                        <ListItemText primary={'LIST'} />
                                    </ListItem>
                                    <ListItem button key={'dam_map_nav'} onClick={this.onDamMapNavClick}>
                                        <ListItemIcon>
                                            <Icon>map</Icon>
                                        </ListItemIcon>
                                        <ListItemText primary={'MAP'} />
                                    </ListItem>
                                </SideMenuItem>
                            </div>
                        }
                        {
                            auth.session?.employee.IsTransformerUser &&
                            <div>
                                <SideMenuItem key={'transformer_nav_header'} icon={<img className={'h30 w30'} src={`${ASSET_BASE}/assets/images/icons/transformer.svg`} />}
                                    title={'Transformers'}>
                                    <ListItem button key={'transformer_nav_header_list_nav'} onClick={this.onTransformerListNavClick}>
                                        <ListItemIcon>
                                            <Icon>list</Icon>
                                        </ListItemIcon>
                                        <ListItemText primary={'LIST'} />
                                    </ListItem>
                                    <ListItem button key={'transformer_nav_header_map_nav'} onClick={this.onTransformerMapNavClick}>
                                        <ListItemIcon>
                                            <Icon>map</Icon>
                                        </ListItemIcon>
                                        <ListItemText primary={'MAP'} />
                                    </ListItem>
                                    {
                                        auth.session.employee.IsTransformerAdmin &&
                                        <ListItem button key={'transformer_nav_accounts'} onClick={this.onTransformerAccountNavClick}>
                                            <ListItemIcon>
                                                <Icon>account_balance</Icon>
                                            </ListItemIcon>
                                            <ListItemText primary={'Accounts'} />
                                        </ListItem>
                                    }
                                    {
                                        auth.session.employee.IsTransformerTariffAdmin &&
                                        <ListItem button key={'trans_journal_nav'} onClick={this.onTransformerJournalsNavClick}>
                                            <ListItemIcon>
                                                <Icon>account_balance</Icon>
                                            </ListItemIcon>
                                            <ListItemText primary={'Journals'} />
                                        </ListItem>
                                    }
                                    {
                                        auth.session.employee.IsTransformerTariffAdmin &&
                                        <SideMenuItem key={'transformer_nav_tariff_nav'} icon={<img className={'h30 w30'} src={`${ASSET_BASE}/assets/images/icons/transformerTariffs.svg`} />}
                                            title={'Tariff Management'}>
                                            <ListItem button key={'LPU_admin_nav'} onClick={this.onLPUAdminNavClick}>
                                                <ListItemIcon>
                                                    <div className={'h30 w30'} />
                                                </ListItemIcon>
                                                <ListItemText primary={'LPU'} />
                                            </ListItem>
                                            <ListItem button key={'SPU_admin_nav'} onClick={this.onSPUAdminNavClick}>
                                                <ListItemIcon>
                                                    <div className={'h30 w30'} />
                                                </ListItemIcon>
                                                <ListItemText primary={'SPU'} />
                                            </ListItem>
                                        </SideMenuItem>
                                    }
                                    {
                                        auth.session.employee.IsTransformerBudgetAdmin &&
                                        <SideMenuItem key={'transformer_nav_budget_nav'} icon={<img className={'h30 w30'} src={`${ASSET_BASE}/assets/images/icons/transformerTariffs.svg`} />}
                                            title={'Budget Management'}>
                                            <ListItem button id={'lpu'} key={'LPU_budget_nav'} onClick={this.onBudgetNavClick}>
                                                <ListItemIcon>
                                                    <div className={'h30 w30'} />
                                                </ListItemIcon>
                                                <ListItemText primary={'LPU'} />
                                            </ListItem>
                                            <ListItem button id={'spu'} key={'SPU_budget_nav'} onClick={this.onBudgetNavClick}>
                                                <ListItemIcon>
                                                    <div className={'h30 w30'} />
                                                </ListItemIcon>
                                                <ListItemText primary={'SPU'} />
                                            </ListItem>
                                            <ListItem button id={'municipality'} key={'municipality_budget_nav'} onClick={this.onBudgetNavClick}>
                                                <ListItemIcon>
                                                    <div className={'h30 w30'} />
                                                </ListItemIcon>
                                                <ListItemText primary={'Municipality'} />
                                            </ListItem>
                                            <ListItem button id={'csv'} key={'csv_budget_nav'} onClick={this.onBudgetCSVNavClick}>
                                                <ListItemIcon>
                                                    <div className={'h30 w30'} />
                                                </ListItemIcon>
                                                <ListItemText primary={'CSV'} />
                                            </ListItem>
                                        </SideMenuItem>
                                    }
                                    <SideMenuItem key={'transformer_nav_capturing_nav'} icon={<img className={'h30 w30'} src={`${ASSET_BASE}/assets/images/icons/transformerTariffs.svg`} />}
                                        title={'Capturing'}>
                                        {
                                            auth.session.employee.IsTransformerLpuTariff &&
                                            <ListItem button key={'LPU_capturing_nav'} onClick={this.onLPUCapturingNavClick}>
                                                <ListItemIcon>
                                                    <div className={'h30 w30'} />
                                                </ListItemIcon>
                                                <ListItemText primary={'LPU Invoice'} />
                                            </ListItem>
                                        }
                                        {
                                            auth.session.employee.IsTransformerLpuTester &&
                                            <ListItem button key={'LPU_meter_nav'} onClick={this.onLPUMeterNavClick}>
                                                <ListItemIcon>
                                                    <div className={'h30 w30'} />
                                                </ListItemIcon>
                                                <ListItemText primary={'LPU Meter'} />
                                            </ListItem>
                                        }
                                        {
                                            auth.session.employee.IsTransformerSpuTariff &&
                                            <ListItem button key={'SPU_capturing_nav'} onClick={this.onSPUCapturingNavClick}>
                                                <ListItemIcon>
                                                    <div className={'h30 w30'} />
                                                </ListItemIcon>
                                                <ListItemText primary={'SPU Invoice'} />
                                            </ListItem>
                                        }
                                        {
                                            auth.session.employee.IsTransformerSpuTester &&
                                            <ListItem button key={'SPU_meter_nav'} onClick={this.onSPUMeterNavClick}>
                                                <ListItemIcon>
                                                    <div className={'h30 w30'} />
                                                </ListItemIcon>
                                                <ListItemText primary={'SPU Meter'} />
                                            </ListItem>
                                        }
                                        {
                                            auth.session.employee.IsTransformerMunicipalityTester &&
                                            <ListItem button key={'Municipality_meter_nav'} onClick={this.onMunicipalityMeterNavClick}>
                                                <ListItemIcon>
                                                    <div className={'h30 w30'} />
                                                </ListItemIcon>
                                                <ListItemText primary={'Municipality Meter'} />
                                            </ListItem>
                                        }
                                        {
                                            auth.session.employee.IsMunicipalityInvoice &&
                                            <ListItem button key={'Municipality_invoice_nav'} onClick={this.onMunicipalityInvoiceNavClick}>
                                                <ListItemIcon>
                                                    <div className={'h30 w30'} />
                                                </ListItemIcon>
                                                <ListItemText primary={'Municipality Invoice'} />
                                            </ListItem>
                                        }
                                        {
                                            auth.session.employee.IsSPSInvoice &&
                                            <ListItem button key={'SPS_invoice_nav'} onClick={this.onSPSInvoiceNavClick}>
                                                <ListItemIcon>
                                                    <div className={'h30 w30'} />
                                                </ListItemIcon>
                                                <ListItemText primary={'SPS Invoice'} />
                                            </ListItem>
                                        }
                                        {
                                            auth.session.employee.IsTransformerPrepaidTester &&
                                            <ListItem button key={'Prepaid_meter_nav'} onClick={this.onPrepaidNavClick}>
                                                <ListItemIcon>
                                                    <div className={'h30 w30'} />
                                                </ListItemIcon>
                                                <ListItemText primary={'Prepaid Amount'} />
                                            </ListItem>
                                        }
                                    </SideMenuItem>
                                </SideMenuItem>
                            </div>
                        }
                        {
                            auth.session?.employee.IsWeatherStationUser &&
                            <div>
                                <SideMenuItem key={'weather_nav_header'} icon={<img className={'h30 w30'} src={`${ASSET_BASE}/assets/images/icons/weather.svg`} />}
                                    title={'Weather Stations'}>
                                    <ListItem button key={'weather_nav_header_list_nav'} onClick={this.onWeatherListNavClick}>
                                        <ListItemIcon>
                                            <Icon>list</Icon>
                                        </ListItemIcon>
                                        <ListItemText primary={'LIST'} />
                                    </ListItem>
                                    <ListItem button key={'weather_nav_header_map_nav'} onClick={this.onWeatherMapNavClick}>
                                        <ListItemIcon>
                                            <Icon>map</Icon>
                                        </ListItemIcon>
                                        <ListItemText primary={'MAP'} />
                                    </ListItem>
                                </SideMenuItem>
                            </div>
                        }
                        {
                            auth.session?.employee.IsMottechUser &&
                            <div>
                                <SideMenuItem key={'mottech_nav_header'} icon={<img className={'h30 w30'} src={`${ASSET_BASE}/assets/images/icons/mottech.svg`} />}
                                    title={'Mottech'}>
                                    <ListItem button key={'mottech_nav_header_list_nav'} onClick={this.onMottechListNavClick}>
                                        <ListItemIcon>
                                            <Icon>list</Icon>
                                        </ListItemIcon>
                                        <ListItemText primary={'LIST'} />
                                    </ListItem>
                                    <ListItem button key={'mottech_nav_header_map_nav'} onClick={this.onMottechMapNavClick}>
                                        <ListItemIcon>
                                            <Icon>map</Icon>
                                        </ListItemIcon>
                                        <ListItemText primary={'MAP'} />
                                    </ListItem>
                                </SideMenuItem>
                            </div>
                        }
                        {
                            auth.session?.employee.IsRiverUser &&
                            <div>
                                <SideMenuItem key={'river_nav_header'} icon={<img className={'h30 w30'} src={`${ASSET_BASE}/assets/images/icons/rivers.svg`} />}
                                    title={'Rivers'}>
                                    <ListItem button key={'river_nav_header_list_nav'} onClick={this.onRiverListNavClick}>
                                        <ListItemIcon>
                                            <Icon>list</Icon>
                                        </ListItemIcon>
                                        <ListItemText primary={'LIST'} />
                                    </ListItem>
                                    <ListItem button key={'river_nav_header_map_nav'} onClick={this.onRiverMapNavClick}>
                                        <ListItemIcon>
                                            <Icon>map</Icon>
                                        </ListItemIcon>
                                        <ListItemText primary={'MAP'} />
                                    </ListItem>
                                </SideMenuItem>
                            </div>
                        }
                        {
                            auth.session?.employee.IsPumpingStationUser &&
                            <div>
                                <SideMenuItem key={'pumpingStation_nav_header'} icon={<img className={'h30 w30'} src={`${ASSET_BASE}/assets/images/icons/pumpingStation.svg`} />}
                                    title={'Pumping Stations'}>
                                    <ListItem button key={'pumpingStation_nav_header_list_nav'} onClick={this.onPumpingStationListNavClick}>
                                        <ListItemIcon>
                                            <Icon>list</Icon>
                                        </ListItemIcon>
                                        <ListItemText primary={'LIST'} />
                                    </ListItem>
                                    <ListItem button key={'pumpingStation_nav_header_map_nav'} onClick={this.onPumpingStationMapNavClick}>
                                        <ListItemIcon>
                                            <Icon>map</Icon>
                                        </ListItemIcon>
                                        <ListItemText primary={'MAP'} />
                                    </ListItem>
                                </SideMenuItem>
                            </div>
                        }
                        {
                            auth.session?.employee.IsFlowmeterUser &&
                            <div>
                                <SideMenuItem key={'flowmeter_nav_header'} icon={<img className={'h30 w30'} src={`${ASSET_BASE}/assets/images/icons/flowmeter.svg`} />}
                                    title={'Flowmeter'}>
                                    <ListItem button key={'flowmeter_nav_header_list_nav'} onClick={this.onFlowmeterListNavClick}>
                                        <ListItemIcon>
                                            <Icon>list</Icon>
                                        </ListItemIcon>
                                        <ListItemText primary={'LIST'} />
                                    </ListItem>
                                    <ListItem button key={'flowmeter_nav_header_map_nav'} onClick={this.onFlowmeterMapNavClick}>
                                        <ListItemIcon>
                                            <Icon>map</Icon>
                                        </ListItemIcon>
                                        <ListItemText primary={'MAP'} />
                                    </ListItem>
                                </SideMenuItem>
                            </div>
                        }
                        {
                            auth.session?.employee.IsMasterDataAdmin &&
                            <div>
                                <SideMenuItem key={'master_data_nav_header'} icon={<img className={'h30 w30'} src={`${ASSET_BASE}/assets/images/icons/masterData.svg`} />}
                                    title={'Master Data'}>
                                    {
                                        auth.session.employee.IsBoreholeMasterDataAdmin &&
                                        <SideMenuItem key={'master_data_borehole_nav_header'} icon={<img className={'h30 w30'} src={`${ASSET_BASE}/assets/images/icons/borehole.svg`} />}
                                            title={'Borehole'}>
                                            <ListItem button key={'borehole_issue_nav'} onClick={this.onBoreholeIssuesNavClick}>
                                                <ListItemIcon>
                                                    <img className={'h30 w30'} src={`${ASSET_BASE}/assets/images/icons/borehole.svg`} />
                                                </ListItemIcon>
                                                <ListItemText primary={'Issues'} />
                                            </ListItem>
                                            <ListItem button key={'motor_nav'} onClick={this.onMotorsNavClick}>
                                                <ListItemIcon>
                                                    <img className={'h30 w30'} src={`${ASSET_BASE}/assets/images/icons/borehole.svg`} />
                                                </ListItemIcon>
                                                <ListItemText primary={'Motors'} />
                                            </ListItem>
                                            <ListItem button key={'motor_size_nav'} onClick={this.onMotorSizesNavClick}>
                                                <ListItemIcon>
                                                    <img className={'h30 w30'} src={`${ASSET_BASE}/assets/images/icons/borehole.svg`} />
                                                </ListItemIcon>
                                                <ListItemText primary={'Motor Sizes'} />
                                            </ListItem>
                                            <ListItem button key={'pumps_nav'} onClick={this.onPumpsNavClick}>
                                                <ListItemIcon>
                                                    <img className={'h30 w30'} src={`${ASSET_BASE}/assets/images/icons/borehole.svg`} />
                                                </ListItemIcon>
                                                <ListItemText primary={'Pumps'} />
                                            </ListItem>
                                        </SideMenuItem>
                                    }
                                    {
                                        auth.session.employee.IsTransformerMasterDataAdmin &&
                                        <SideMenuItem key={'master_data_transformer_nav_header'} icon={<img className={'h30 w30'} src={`${ASSET_BASE}/assets/images/icons/transformer.svg`} />}
                                            title={'Transformer'}>
                                            <ListItem button key={'transformer_group_nav'} onClick={this.onTransformerGroupNavClick}>
                                                <ListItemIcon>
                                                    <img className={'h30 w30'} src={`${ASSET_BASE}/assets/images/icons/transformer.svg`} />
                                                </ListItemIcon>
                                                <ListItemText primary={'Groups'} />
                                            </ListItem>
                                            <ListItem button key={'transformer_usage_nav'} onClick={this.onTransformerUsageNavClick}>
                                                <ListItemIcon>
                                                    <img className={'h30 w30'} src={`${ASSET_BASE}/assets/images/icons/transformer.svg`} />
                                                </ListItemIcon>
                                                <ListItemText primary={'Usage'} />
                                            </ListItem>
                                        </SideMenuItem>
                                    }
                                    {
                                        auth.session.employee.IsPumpingStationAdmin &&
                                        <SideMenuItem key={'master_data_pumping_station_nav_header'} icon={<img className={'h30 w30'} src={`${ASSET_BASE}/assets/images/icons/pumpingStation.svg`} />}
                                            title={'Pumping Station'}>
                                            <ListItem button id='filterSize' key={'pumping_station_md_filter_size'} onClick={this.onPumpingStationMasterDataNavClick}>
                                                <ListItemIcon>
                                                </ListItemIcon>
                                                <ListItemText primary={'Filter Size'} />
                                            </ListItem>
                                            <ListItem button id='filterType' key={'pumping_station_md_filter_type'} onClick={this.onPumpingStationMasterDataNavClick}>
                                                <ListItemIcon>
                                                </ListItemIcon>
                                                <ListItemText primary={'Filter Type'} />
                                            </ListItem>
                                            <ListItem button id='flowmeterMake' key={'pumping_station_md_flowmeter_make'} onClick={this.onPumpingStationMasterDataNavClick}>
                                                <ListItemIcon>
                                                </ListItemIcon>
                                                <ListItemText primary={'Flowmeter Make'} />
                                            </ListItem>
                                            <ListItem button id='flowmeterPulse' key={'pumping_station_md_flowmeter_pulse'} onClick={this.onPumpingStationMasterDataNavClick}>
                                                <ListItemIcon>
                                                </ListItemIcon>
                                                <ListItemText primary={'Flowmeter Pulse'} />
                                            </ListItem>
                                            <ListItem button id='flowmeterSize' key={'pumping_station_md_flowmeter_size'} onClick={this.onPumpingStationMasterDataNavClick}>
                                                <ListItemIcon>
                                                </ListItemIcon>
                                                <ListItemText primary={'Flowmeter Size'} />
                                            </ListItem>
                                            <ListItem button id='motorEfficiency' key={'pumping_station_md_motor_efficiency'} onClick={this.onPumpingStationMasterDataNavClick}>
                                                <ListItemIcon>
                                                </ListItemIcon>
                                                <ListItemText primary={'Motor Efficiency'} />
                                            </ListItem>
                                            <ListItem button id='motorMake' key={'pumping_station_md_motor_make'} onClick={this.onPumpingStationMasterDataNavClick}>
                                                <ListItemIcon>
                                                </ListItemIcon>
                                                <ListItemText primary={'Motor Make'} />
                                            </ListItem>
                                            <ListItem button id='motorMounting' key={'pumping_station_md_motor_mounting'} onClick={this.onPumpingStationMasterDataNavClick}>
                                                <ListItemIcon>
                                                </ListItemIcon>
                                                <ListItemText primary={'Motor Mounting'} />
                                            </ListItem>
                                            <ListItem button id='motorPole' key={'pumping_station_md_motor_pole'} onClick={this.onPumpingStationMasterDataNavClick}>
                                                <ListItemIcon>
                                                </ListItemIcon>
                                                <ListItemText primary={'Motor Pole'} />
                                            </ListItem>
                                            <ListItem button id='motorSize' key={'pumping_station_md_motor_size'} onClick={this.onPumpingStationMasterDataNavClick}>
                                                <ListItemIcon>
                                                </ListItemIcon>
                                                <ListItemText primary={'Motor Size'} />
                                            </ListItem>
                                            <ListItem button id='motorType' key={'pumping_station_md_motor_type'} onClick={this.onPumpingStationMasterDataNavClick}>
                                                <ListItemIcon>
                                                </ListItemIcon>
                                                <ListItemText primary={'Motor Type'} />
                                            </ListItem>
                                            <ListItem button id='pumpMake' key={'pumping_station_md_pump_make'} onClick={this.onPumpingStationMasterDataNavClick}>
                                                <ListItemIcon>
                                                </ListItemIcon>
                                                <ListItemText primary={'Pump Makes'} />
                                            </ListItem>
                                            <ListItem button id='pumpType' key={'pumping_station_md_pump_type'} onClick={this.onPumpingStationMasterDataNavClick}>
                                                <ListItemIcon>
                                                </ListItemIcon>
                                                <ListItemText primary={'Pump Types'} />
                                            </ListItem>
                                            <ListItem button id='voluteCasingMake' key={'pumping_station_md_volute_casing_make'} onClick={this.onPumpingStationMasterDataNavClick}>
                                                <ListItemIcon>
                                                </ListItemIcon>
                                                <ListItemText primary={'Volute Casing Makes'} />
                                            </ListItem>
                                        </SideMenuItem>
                                    }
                                    {
                                        auth.session.employee.IsDivisionAdmin &&
                                        <ListItem button key={'division_nav'} onClick={this.onDivionNavClick}>
                                            <ListItemIcon>
                                                <img className={'h30 w30'} src={`${ASSET_BASE}/assets/images/icons/divisions.svg`} />
                                            </ListItemIcon>
                                            <ListItemText primary={'Division'} />
                                        </ListItem>
                                    }
                                    {
                                        auth.session.employee.IsCompanyAdmin &&
                                        <ListItem button key={'company_nav'} onClick={this.onCompanyNavClick}>
                                            <ListItemIcon>
                                            </ListItemIcon>
                                            <ListItemText primary={'Company'} />
                                        </ListItem>
                                    }
                                </SideMenuItem>
                            </div>
                        }
                        {
                            auth.session?.employee.IsEmployeeAdmin &&
                            <div>
                                <SideMenuItem key={'employee_nav_header'} icon={<img className={'h30 w30'} src={`${ASSET_BASE}/assets/images/icons/rights.svg`} />}
                                    title={'Rights'}>
                                    <ListItem button key={'employee_nav'} onClick={this.onEmployeeNavClick}>
                                        <ListItemIcon>
                                            <img className={'h30 w30'} src={`${ASSET_BASE}/assets/images/icons/users.svg`} />
                                        </ListItemIcon>
                                        <ListItemText primary={'Users'} />
                                    </ListItem>
                                </SideMenuItem>
                            </div>
                        }
                    </div>
                    <div className='bcg2'>
                        <MenuItem onClick={this.logout}>Logout<span className={'flx1'} /> <img className={'h20'} src={`${ASSET_BASE}/assets/images/logout.svg`} /> </MenuItem>
                    </div>
                </Drawer>
            </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
    };
};

export default connect(
    mapStateToProps,
)(NavDrawer);
