import { dispatch, getState } from '../../store';
import AuthActions from './actions';
import firebaseApp, { firebaseGoogleProvider, FirebaseService } from '../../services/firebaseService';
import generalFunctions from '../general/functions';
import HttpService from '../../services/httpService';
import { EmployeeHelper, IEmployee } from '../../@types/employee';
import HttpGeoServerService from '../../services/geoserverHttpService';

export default class AuthFunctions {
    private static authUnsubscribe ?: firebase.Unsubscribe;
    private static employeeUnsubscribe ?: () => void;

    public static readonly setAuthState = async () => {
        dispatch(AuthActions.authSetLoggingIn(true));

        if (AuthFunctions.authUnsubscribe) AuthFunctions.authUnsubscribe();

        try {
            await FirebaseService.initRemoteConfig();

            if (firebaseApp.auth().currentUser) {
                await firebaseApp.auth().currentUser?.reload();
            }

            AuthFunctions.authUnsubscribe = firebaseApp.auth().onAuthStateChanged(AuthFunctions.authObserver);
        } catch (ex) {
            generalFunctions.generalShowError(ex, 'Error with Auth');
            AuthActions.authSetLoggingIn(false);
        }
    };

    private static readonly authObserver = async (firebaseUser : firebase.User | null) => {
        if (AuthFunctions.employeeUnsubscribe) AuthFunctions.employeeUnsubscribe();

        if (firebaseUser) {
            try {
                AuthFunctions.employeeUnsubscribe = EmployeeHelper.doc(firebaseUser.uid).onSnapshot((data) => {
                    const authState = getState().auth;
                    const employee = !authState.session ? undefined : authState.session.employee;
                    if (data.exists) {
                        setTimeout(() => {
                            AuthFunctions.initialize(firebaseUser, data);
                        }, employee ? 2000 : 0); // Just wait 2sec before updating redux so custom claims can change.
                    } else if (employee) {
                        AuthFunctions.firebaseSignOut();
                    }
                }, (error) => {
                    generalFunctions.generalShowErrorSnackbar(error.message);
                    AuthFunctions.logoutUser();
                });
            } catch {
                AuthFunctions.logoutUser();
            }
        } else {
            AuthFunctions.logoutUser();
        }
    };

    private static readonly initialize = async (firebaseUser : firebase.User, data : firebase.firestore.DocumentSnapshot<IEmployee>) => {
        const token = await firebaseUser.getIdTokenResult(true);

        const employee = data.data();
        HttpService.initializeInterceptor(token.token);
        HttpGeoServerService.initializeInterceptor();
        dispatch(AuthActions.authSetSession(!employee ? null : {
            employee,
            firebaseUser,
        }));
        dispatch(AuthActions.authSetLoggedIn(true));
        dispatch(AuthActions.authSetLoggingIn(false));
    };

    private static readonly logoutUser = () => {
        dispatch(AuthActions.authSetSession(null));
        dispatch(AuthActions.authSetLoggedIn(false));
        dispatch(AuthActions.authSetLoggingIn(false));
        HttpService.initializeInterceptor('');
        if (AuthFunctions.employeeUnsubscribe) AuthFunctions.employeeUnsubscribe();
    };

    public static firebaseSignIn = async () => {
        try {
            dispatch(AuthActions.authSetLoggingIn(true));

            const provider = firebaseGoogleProvider;
            await firebaseApp.auth().signInWithPopup(provider);
        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar('An error occurred while trying to sign you in');
            dispatch(AuthActions.authSetLoggingIn(false));
        }
    };

    public static readonly firebaseSignOut = async () => {
        try {
            dispatch(AuthActions.authSetLoggingIn(true));

            await firebaseApp.auth().signOut();
        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar('An error occurred while trying to sign you out');
        } finally {
            dispatch(AuthActions.authSetLoggingIn(false));
        }
    };

    public static readonly hasRight = (url : string, search ?: string) => {
        const authState = getState().auth;

        if (!authState.session) return false;

        if (url.startsWith('/masterData/pumpingStation/')) {
            return !!authState.session.employee.IsPumpingStationAdmin;
        }

        switch (url) {
            case '/borehole/list':
            case '/borehole/map':
                return !!authState.session.employee.IsBoreholeUser;
            case '/transformer/capturing/spu':
                return !!authState.session.employee.IsTransformerSpuTariff;
            case '/transformer/capturing/lpu':
                return !!authState.session.employee.IsTransformerLpuTariff;
            case '/transformer/capturing/spu/meter':
                return !!authState.session.employee.IsTransformerSpuTester;
            case '/transformer/capturing/lpu/meter':
                return !!authState.session.employee.IsTransformerLpuTester;
            case '/transformer/capturing/municipality/meter':
                return !!authState.session.employee.IsTransformerMunicipalityTester;
            case '/transformer/capturing/municipality/invoice':
            case '/transformer/capturing/municipality/invoice/capture':
                return !!authState.session.employee.IsMunicipalityInvoice;
            case '/transformer/capturing/sps/invoice':
            case '/transformer/capturing/sps/invoice/capture':
                return !!authState.session.employee.IsSPSInvoice;
            case '/transformer/capturing/prepaid/meter':
                return !!authState.session.employee.IsTransformerPrepaidTester;
            case '/transformer/capturing':
                if (search === '?type=spu') {
                    return !!authState.session.employee.IsTransformerSpuTariff;
                }
                if (search === '?type=lpu') {
                    return !!authState.session.employee.IsTransformerLpuTariff;
                }
                return false;
            case '/transformer/list':
            case '/transformer/map':
                return !!authState.session.employee.IsTransformerUser;
            case '/transformer/journals':
            case '/transformer/accounts':
                return !!authState.session.employee.IsTransformerAdmin;
            case '/transformer/budget':
            case '/transformer/budget/csv':
                return !!authState.session.employee.IsTransformerBudgetAdmin;
            case '/transformer/tariffs':
                return !!authState.session.employee.IsTransformerTariffAdmin;
            case '/weather/list':
            case '/weather/map':
                return !!authState.session.employee.IsWeatherStationUser;
            case '/dam/list':
            case '/dam/map':
                return !!authState.session.employee.IsDamUser;
            case '/pumpingStation/list':
            case '/pumpingStation/map':
                return !!authState.session.employee.IsPumpingStationUser;
            case '/river/list':
            case '/river/map':
                return !!authState.session.employee.IsRiverUser;
            case '/mottech/list':
            case '/mottech/map':
                return !!authState.session.employee.IsMottechUser;
            case '/employee':
                return !!authState.session.employee.IsEmployeeAdmin;
            case '/division':
                return !!authState.session.employee.IsDivisionAdmin;
            case '/masterData/company':
                return !!authState.session.employee.IsCompanyAdmin;
            case '/masterData/pumps':
            case '/masterData/motors':
            case '/masterData/motorSizes':
            case '/masterData/boreholeIssues':
            case '/borehole/runtimesOverride':
                return !!authState.session.employee.IsBoreholeMasterDataAdmin;
            case '/masterData/transformer/group':
            case '/masterData/transformer/usage':
                return !!authState.session.employee.IsTransformerMasterDataAdmin;
            case '/flowmeter/list':
            case '/flowmeter/map':
                return !!authState.session.employee.IsFlowmeterUser;
            case '/chemical_filling_point/list':
            case '/chemical_filling_point/map':
                return !!authState.session.employee.IsFillingPointUser;
        }

        return false;
    };
}
