import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DialogContent from '@material-ui/core/DialogContent';
import { Typography, DialogActions, Button, FormControl, FormHelperText, TextField, InputAdornment } from '@material-ui/core';
import GeneralFunctions from '../../../store/general/functions';
import { IUserSession } from '../../../@types/employee';
import { ITransformer } from '../../../@types/model/transformer/transformer';
import TransformerGuaranteeHelper, { ITransformerGuarantee } from '../../../@types/model/transformer/transformerGuarantee';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Moment } from 'moment';
import moment from 'moment';
import { DATE_FORMAT_DEFAULT_NO_TIME } from '../../../appConstants';

interface IEditTransformerGuaranteeProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    onClose ?: () => void;
    session : IUserSession;
    transformer : ITransformer;
    value : ITransformerGuarantee | null;
}

interface IEditTransformerGuaranteeState {
    isLoading : boolean;

    date : Moment | null;
    cash : number | null;
    interest : number | null;
    nonCashDeposit : number | null;
    bank : string;
    guaranteeNumber : string;
    guaranteeAmount : number | null;
    blanket : string;
    attention : string;

    comment : string;
}

export default class EditTransformerGuaranteeDialog extends React.PureComponent<IEditTransformerGuaranteeProps, IEditTransformerGuaranteeState> {
    constructor(props : IEditTransformerGuaranteeProps) {
        super(props);
        this.state = {
            isLoading: false,
            date: null,
            cash: null,
            interest: null,
            nonCashDeposit: null,
            bank: '',
            guaranteeNumber: '',
            guaranteeAmount: null,
            blanket: '',
            attention: '',
            comment: '',
        };
    }

    public readonly onClose = () => {
        if (!this.props.onClose) return;
        this.props.onClose();
    };

    public componentDidUpdate(prevProps : Readonly<IEditTransformerGuaranteeProps>) : void {
        if (!prevProps.value && this.props.value) {
            const {
                value,
            } = this.props;

            this.setState({
                date: !value.date ? null : moment.utc(value.date),
                cash: !value.cash ? null : value.cash,
                interest: !value.interest ? null : value.interest,
                nonCashDeposit: !value.nonCashDeposit ? null : value.nonCashDeposit,
                bank: !value.bank ? '' : value.bank,
                guaranteeNumber: !value.guaranteeNumber ? '' : value.guaranteeNumber,
                guaranteeAmount: !value.guaranteeAmount ? null : value.guaranteeAmount,
                blanket: !value.blanket ? '' : value.blanket,
                attention: !value.attention ? '' : value.attention,
                comment: !value.comment ? '' : value.comment,
            });
        }
    }

    private readonly onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.save();
    };

    private readonly save = async () => {
        const {
            session,
            transformer,
            value,
        } = this.props;

        const {
            isLoading,
            date,
            cash,
            interest,
            nonCashDeposit,
            bank,
            guaranteeNumber,
            guaranteeAmount,
            blanket,
            attention,
            comment,
        } = this.state;
        
        if (!value) return;

        if (isLoading) return;
        if (!date) return;

        try {
            this.setState({
                isLoading: true,
            });

            await TransformerGuaranteeHelper.save({
                ...value,
                date: date.valueOf(),
                cash,
                interest,
                nonCashDeposit,
                bank,
                guaranteeNumber,
                guaranteeAmount,
                blanket,
                attention,
                comment,
                transformerRef: transformer.ref.id,
                isWeb: true,
                updatedBy: session.firebaseUser.uid,
                updatedByEmployee: session.employee.EmployeeNumber ?? '',
                updatedByName: session.firebaseUser.displayName ?? '',
                updatedOn: moment.utc().valueOf(),
            });

            this.onClose();
        } catch (ex) {
            GeneralFunctions.generalShowError(ex, 'Error saving guarantee.');
        } finally {
            this.setState({
                isLoading: false,
            });
        }
    };

    private readonly onDateChange = (date : moment.Moment | null) => {
        if (!date) return;
        this.setState({
            date: date.startOf('day').asUTC(),
        });
    };

    private readonly onCashChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.currentTarget.value && !Number(event.currentTarget.value)) return;
        this.setState({
            cash: !event.currentTarget.value ? null : Number(event.currentTarget.value),
        });
    };

    private readonly onInterestChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.currentTarget.value && !Number(event.currentTarget.value)) return;
        this.setState({
            interest: Number(event.currentTarget.value),
        });
    };

    private readonly onNonCashDepositChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.currentTarget.value && !Number(event.currentTarget.value)) return;
        this.setState({
            nonCashDeposit: Number(event.currentTarget.value),
        });
    };

    private readonly onBankChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            bank: event.currentTarget.value,
        });
    };

    private readonly onGuaranteeNumberChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            guaranteeNumber: event.currentTarget.value,
        });
    };

    private readonly onGuaranteeAmountChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.currentTarget.value && !Number(event.currentTarget.value)) return;
        this.setState({
            guaranteeAmount: Number(event.currentTarget.value),
        });
    };

    private readonly onBlanketChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            blanket: event.currentTarget.value,
        });
    };

    private readonly onAttentionChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            attention: event.currentTarget.value,
        });
    };

    private readonly onCommentChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            comment: event.currentTarget.value,
        });
    };

    public readonly render = () => {
        const {
            transition,
            maxWidth,
            fullWidth,
            fullScreen,
            value,
        } = this.props;
        const {
            isLoading,
            date,
            cash,
            interest,
            nonCashDeposit,
            bank,
            guaranteeNumber,
            guaranteeAmount,
            blanket,
            attention,
            comment,
        } = this.state;
        return (
            <Dialog
                open={!!value}
                TransitionComponent={transition}
                transitionDuration={500}
                maxWidth={maxWidth}
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                aria-labelledby='edit-guarantee-dialog-title'
                aria-describedby='edit-guarantee-description'>
                <AppBar className='fdr posr aic jcc' position='static'>
                    <Toolbar className={'fdr flx1 aic jcc'}>
                        <Typography variant='h5' color='inherit'>
                            {!value?.id ? 'Add' : 'Edit'} Guarantee
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                    <DialogContent className='fdc'>
                        <div className={'fdr flx1 aifs'}>
                            <div className={'flx1 ais p5 mb10 pr10'}>
                                <KeyboardDatePicker
                                    value={date}
                                    label='Date'
                                    format={DATE_FORMAT_DEFAULT_NO_TIME}
                                    fullWidth
                                    onChange={this.onDateChange}
                                    required
                                    disabled={isLoading}
                                    error={!date}
                                />
                                {
                                    !date &&
                                    <FormHelperText error>Required</FormHelperText>
                                }
                            </div>
                            <div className={'flx1 ais p5 mb10 pl10 pr10'}>
                                <FormControl fullWidth>
                                    <TextField
                                        autoComplete='off'
                                        id='cash'
                                        label='Cash'
                                        value={cash ?? ''}
                                        onChange={this.onCashChange}
                                        type='number'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position='start'>
                                                    R
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </div>
                            <div className={'flx1 ais p5 mb10 pl10'}>
                                <FormControl fullWidth>
                                    <TextField
                                        autoComplete='off'
                                        id='interest'
                                        label='Interest'
                                        value={interest ?? ''}
                                        onChange={this.onInterestChange}
                                        type='number'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position='start'>
                                                    R
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div className={'fdr flx1 aifs mt15'}>
                            <div className={'flx1 ais p5 mb10 pr10'}>
                                <FormControl fullWidth>
                                    <TextField
                                        autoComplete='off'
                                        id='nonCashDeposit'
                                        label='Non Cash Deposit'
                                        value={nonCashDeposit ?? ''}
                                        onChange={this.onNonCashDepositChange}
                                        type='number'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position='start'>
                                                    R
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </div>
                            <div className={'flx1 ais p5 mb10 pl10 pr10'}>
                                <FormControl fullWidth>
                                    <TextField
                                        autoComplete='off'
                                        id='bank'
                                        label='Bank Name'
                                        value={bank}
                                        onChange={this.onBankChange}
                                    />
                                </FormControl>
                            </div>
                            <div className={'flx1 ais p5 mb10 pl10'}>
                                <FormControl fullWidth>
                                    <TextField
                                        autoComplete='off'
                                        id='guaranteeNumber'
                                        label='Guarantee Number'
                                        value={guaranteeNumber}
                                        onChange={this.onGuaranteeNumberChange}
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div className={'fdr flx1 aifs mt15'}>
                            <div className={'flx1 ais p5 mb10 pr10'}>
                                <FormControl fullWidth>
                                    <TextField
                                        autoComplete='off'
                                        id='guaranteeAmount'
                                        label='Guarantee Amount'
                                        value={guaranteeAmount ?? ''}
                                        onChange={this.onGuaranteeAmountChange}
                                        type='number'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position='start'>
                                                    R
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </div>
                            <div className={'flx1 ais p5 mb10 pl10 pr10'}>
                                <FormControl fullWidth>
                                    <TextField
                                        autoComplete='off'
                                        id='blanket'
                                        label='Blanket'
                                        value={blanket}
                                        onChange={this.onBlanketChange}
                                    />
                                </FormControl>
                            </div>
                            <div className={'flx1 ais p5 mb10 pl10'}>
                                <FormControl fullWidth>
                                    <TextField
                                        autoComplete='off'
                                        id='attention'
                                        label='Attention'
                                        value={attention}
                                        onChange={this.onAttentionChange}
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div className={'fdr flx1 aifs mt15'}>
                            <div className={'flx2 ais p5 mb10 pr10'}>
                                <FormControl fullWidth>
                                    <TextField
                                        autoComplete='off'
                                        id='comment'
                                        label='Comment'
                                        value={comment}
                                        onChange={this.onCommentChange}
                                    />
                                </FormControl>
                            </div>
                            <div className={'flx1 ais p5 mb10 pl10'}>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                            OK
                        </Button>
                        <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                            CANCEL
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    };
}
