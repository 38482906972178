import moment, { Moment } from 'moment';
import uuid from 'uuid';
import firebaseApp, { FirebaseService } from '../../../services/firebaseService';
import { IDepricatedBaseClass } from '../base';
import { IFileMetaData } from '../files/fileMetaData';
import BoreholeHelper from './borehole';
import * as Yup from 'yup';
import { YupSchema } from '../../../services/helper/yupHelper';
import lodash from 'lodash';

export const BoreholeCalibrationStages : [0,1,2,3,4] = [0,1,2,3,4];
export type BoreholeCalibrationStagesType = typeof BoreholeCalibrationStages[number];

export const BoreholeCalibrationTimes : [1,2,3,4,5,7,10,15] = [1,2,3,4,5,7,10,15];
export type BoreholeCalibrationTimesType = typeof BoreholeCalibrationTimes[number];

export const BoreholeCalibrationFinalStageTimes : [5,10,15,20,30,40,50,60] = [5,10,15,20,30,40,50,60];
export type BoreholeCalibrationFinalStageTimesType = typeof BoreholeCalibrationFinalStageTimes[number];

export interface IBoreholeCalibration extends IDepricatedBaseClass<IBoreholeCalibration> {
    Borehole : firebase.firestore.DocumentReference;
    GUID : string;
    TestRig : firebase.firestore.DocumentReference | null;
    TestRigNr : string | null;
    InstalledYield : number;
    CasingDiameter : number;
    ConcreteBlockHeight : number;
    CasingHeight : number;
    HoleDepth : number;
    BeforePiezoLevel : number;
    AfterPiezoLevel : number;
    PiezoHeight : number | null;
    PumpDepth : number;
    AvailableDrawdown : number;
    Note : string;
    NeighbouringBoreholes : Array<IBoreholeCalibrationNeighbour>;
    BoreholeCalibrationStages : Record<BoreholeCalibrationStagesType, IBoreholeCalibrationStage>;
    RecoveryEntries : Array<IBoreholeCalibrationStageEntry>;
    IsActive : boolean;
    IsSent : boolean;
    Date : firebase.firestore.Timestamp | null;

    DocumentName : string | null;
    DocumentURL : string | null;
    EmployeeNumber : string | null;
    EmployeeName : string | null;
    Geo : firebase.firestore.GeoPoint | null;
    Elevation : number | null;

    Achieved : Record<BoreholeCalibrationStagesType, IBoreholeCalibrationDrawdownAchieved> | null;

    IsWeb : boolean | null;
}

export interface IBoreholeCalibrationDrawdownAchieved {
    GroundLevel : number;
    PercentageOfDrawdown : number;
}

export interface IBoreholeCalibrationNeighbour {
    BoreholeCode : string;
    WaterLevel : number;
    Time : string | null;
}

export interface IBoreholeCalibrationStage {
    CanComplete : boolean;
    IsCompleted : boolean;
    StageEntries : Array<IBoreholeCalibrationStageEntry>;
    IsActive : boolean;
}

export interface IBoreholeCalibrationStageEntry {
    Time : number;
    GroundLevel : number;
    AbstractionRate : number;
    IsActive : boolean;
}

export interface IBoreholeCalibrationFormValues {
    guid : string;
    date : Moment;

    employeeNumber : string | null;
    employeeName : string | null;

    installedYield : number | null;
    casingDiameter : number | null;
    casingHeight : number | null;
    concreteBlockHeight : number | null;
    holeDepth : number | null;
    beforePiezoLevel : number | null;
    afterPiezoLevel : number | null;
    piezoHeight : number | null;
    availableDrawdown : number | null;
    pumpDepth : number | null;

    latitude : number | null;
    longitude : number | null;
    elevation : number | null;
    
    neighbouringBoreholes : Array<IBoreholeCalibrationNeighbourFormValues>;
    stages : Record<BoreholeCalibrationStagesType, IBoreholeCalibrationStageFormValues>;
    recoveryEntries : Array<IBoreholeCalibrationStageEntryFormValues>;

    achieved : Record<BoreholeCalibrationStagesType, IBoreholeCalibrationDrawdownAchievedFormValues>;

    note : string;
}

export interface IBoreholeCalibrationNeighbourFormValues {
    boreholeCode : string;
    waterLevel : number;
    time : Moment;
}

export interface IBoreholeCalibrationStageFormValues {
    canComplete : boolean;
    isCompleted : boolean;
    stageEntries : Array<IBoreholeCalibrationStageEntryFormValues>;
}

export interface IBoreholeCalibrationStageEntryFormValues {
    time : BoreholeCalibrationTimesType | BoreholeCalibrationFinalStageTimesType;
    groundLevel : number | null;
    abstractionRate : number | null;
}

export interface IBoreholeCalibrationDrawdownAchievedFormValues {
    groundLevel : number | null;
    percentageOfDrawdown : number | null;
}

type YupShape = Record<keyof IBoreholeCalibrationFormValues, YupSchema>;
type YupNeighbourShape = Record<keyof IBoreholeCalibrationNeighbourFormValues, YupSchema>;
type YupStageRecordShape = Record<keyof Record<BoreholeCalibrationStagesType, IBoreholeCalibrationStageFormValues>, YupSchema>;
type YupStageShape = Record<keyof IBoreholeCalibrationStageFormValues, YupSchema>;
type YupStageEntryShape = Record<keyof IBoreholeCalibrationStageEntryFormValues, YupSchema>;
type YupDrawdownAchievedShape = Record<keyof IBoreholeCalibrationDrawdownAchievedFormValues, YupSchema>;

const yupStageEntrySchema = Yup.object<YupStageEntryShape>().shape({
    abstractionRate: Yup.number().nullable().when(['groundLevel'], {
        is: (groundLevel : number) => !!groundLevel,
        then: Yup.number().required('Required'),
    }),
    groundLevel: Yup.number().nullable().when(['abstractionRate'], {
        is: (abstractionRate : number) => !!abstractionRate,
        then: Yup.number().required('Required'),
    }),
    time: Yup.number().required('Required').oneOf(BoreholeCalibrationTimes, 'Invalid'),
}, [[
    'groundLevel', 'abstractionRate'
]]);

const yupRecoveryStageEntrySchema = Yup.object<YupStageEntryShape>().shape({
    abstractionRate: Yup.number().nullable(),
    groundLevel: Yup.number().nullable(),
    time: Yup.number().required('Required').oneOf(BoreholeCalibrationTimes, 'Invalid'),
}, [[
    'groundLevel', 'abstractionRate'
]]);

const yupLastStageEntrySchema = Yup.object<YupStageEntryShape>().shape({
    abstractionRate: Yup.number().nullable().when(['groundLevel'], {
        is: (groundLevel : number) => !!groundLevel,
        then: Yup.number().required('Required'),
    }),
    groundLevel: Yup.number().nullable().when(['abstractionRate'], {
        is: (abstractionRate : number) => !!abstractionRate,
        then: Yup.number().required('Required'),
    }),
    time: Yup.number().required('Required').oneOf(BoreholeCalibrationFinalStageTimes, 'Invalid'),
}, [[
    'groundLevel', 'abstractionRate'
]]);

const yupStageSchema = Yup.object<YupStageShape>({
    canComplete: Yup.boolean().required('Required'),
    isCompleted: Yup.boolean().required('Required'),
    stageEntries: Yup.array(yupStageEntrySchema,),
});

const yupLastStageSchema = Yup.object<YupStageShape>({
    canComplete: Yup.boolean().required('Required'),
    isCompleted: Yup.boolean().required('Required'),
    stageEntries: Yup.array(yupLastStageEntrySchema,),
});

const yupAchievedSchema = Yup.object<YupDrawdownAchievedShape>().shape({
    percentageOfDrawdown: Yup.number().nullable().when(['groundLevel'], {
        is: (groundLevel : number) => !!groundLevel,
        then: Yup.number().required('Required'),
    }),
    groundLevel: Yup.number().nullable().when(['percentageOfDrawdown'], {
        is: (percentageOfDrawdown : number) => !!percentageOfDrawdown,
        then: Yup.number().required('Required'),
    }),
}, [[
    'groundLevel', 'percentageOfDrawdown'
]]);

export default class BoreholeCalibrationHelper {
    public static readonly COLLECTION_NAME = 'borehole_calibration';

    private static converter : firebase.firestore.FirestoreDataConverter<IBoreholeCalibration> = {
        fromFirestore: (snapshot) => {
            return BoreholeCalibrationHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : IBoreholeCalibration) : firebase.firestore.DocumentData => {
            const { ref: _, ...firestoreObject } = data;
            return {
                ...firestoreObject,
            };
        },
    };

    public static fromFirestoreDocument(doc : firebase.firestore.DocumentSnapshot) {
        const data = doc.data() as IBoreholeCalibration | undefined;

        if (!data) {
            throw new Error(`Document does not exist! ${doc.id}`);
        }

        const result : IBoreholeCalibration = { 
            ...data,
            ref: doc.ref.withConverter(BoreholeCalibrationHelper.converter),
            Date: data.Date ?? data.CreatedOn,
            DocumentURL: data.DocumentURL ?? null,
            DocumentName: data.DocumentName ?? null,
            EmployeeNumber: data.EmployeeNumber ?? null,
            EmployeeName: data.EmployeeName ?? null,
            PiezoHeight: data.PiezoHeight ?? null,
            Geo: data.Geo ?? null,
            Elevation: data.Elevation ?? null,
            IsWeb: data.IsWeb ?? null,
            RecoveryEntries: BoreholeCalibrationTimes.map(time => {
                const entry = data.RecoveryEntries.find(x => x.Time === time);

                return {
                    AbstractionRate: entry?.AbstractionRate ?? 0,
                    GroundLevel: entry?.GroundLevel ?? 0,
                    IsActive: entry?.IsActive ?? (!!entry && !!entry.GroundLevel),
                    Time: time,
                };
            }),
            BoreholeCalibrationStages: BoreholeCalibrationStages.reduce((current, stage) => {
                const boreholeCalibrationStage = data.BoreholeCalibrationStages[stage] as IBoreholeCalibrationStage | null;

                current[stage] = {
                    CanComplete: boreholeCalibrationStage?.CanComplete ?? true,
                    IsCompleted: boreholeCalibrationStage?.CanComplete ?? false,
                    IsActive: boreholeCalibrationStage?.CanComplete ?? true,
                    StageEntries: stage === 4 ? BoreholeCalibrationFinalStageTimes.map(time => {
                        const entry = boreholeCalibrationStage?.StageEntries.find(x => x.Time === time);
                        return {
                            AbstractionRate: entry?.AbstractionRate ?? 0,
                            GroundLevel: entry?.GroundLevel ?? 0,
                            IsActive: entry?.IsActive ?? (!!entry && !!entry.AbstractionRate && !!entry.GroundLevel),
                            Time: time,
                        };
                    }) : BoreholeCalibrationTimes.map(time => {
                        const entry = boreholeCalibrationStage?.StageEntries.find(x => x.Time === time);
                        return {
                            AbstractionRate: entry?.AbstractionRate ?? 0,
                            GroundLevel: entry?.GroundLevel ?? 0,
                            IsActive: entry?.IsActive ?? !!entry,
                            Time: time,
                        };
                    }),
                };

                return current;
            }, {} as Record<BoreholeCalibrationStagesType, IBoreholeCalibrationStage>)
        };

        return result;
    }

    public static delete(id : string) {
        return firebaseApp
            .firestore()
            .collection(this.COLLECTION_NAME)
            .doc(id)
            .delete();
    }

    public static update(cal : IBoreholeCalibration) {
        return cal.ref.set(cal, {
            merge: true,
        });
    }

    public static collection() {
        return firebaseApp
            .firestore()
            .collection(this.COLLECTION_NAME)
            .withConverter(this.converter);
    }

    public static doc(id ?: string) {
        if (!id) {
            return firebaseApp.firestore().collection(BoreholeCalibrationHelper.COLLECTION_NAME).withConverter(BoreholeCalibrationHelper.converter).doc();
        }

        return firebaseApp.firestore().collection(BoreholeCalibrationHelper.COLLECTION_NAME).withConverter(BoreholeCalibrationHelper.converter).doc(id);
    }

    public static listen(boreholeCode : string) {
        return firebaseApp
            .firestore()
            .collection(this.COLLECTION_NAME)
            .where('Borehole', '==', BoreholeHelper.doc(boreholeCode))
            .withConverter(this.converter);
    }

    public static uploadFile(boreholeCode : string, file : File, metadata : IFileMetaData) {
        return FirebaseService.fileUpload(file, `borehole/${boreholeCode}/borehole_calibration/${new Date().valueOf()}_${file.name}`, metadata);
    }

    public static initialFormValues(boreholeCalibration ?: IBoreholeCalibration) : IBoreholeCalibrationFormValues {
        return {
            guid: boreholeCalibration?.GUID ?? uuid.v4(),
            date: moment.utc(boreholeCalibration?.Date?.toMillis() ?? boreholeCalibration?.CreatedOn.toMillis()).startOf('day'),
            employeeName: boreholeCalibration?.EmployeeName ?? '',
            employeeNumber: boreholeCalibration?.EmployeeNumber ?? '',
            installedYield: boreholeCalibration?.InstalledYield ?? null,
            casingDiameter: boreholeCalibration?.CasingDiameter ?? null,
            casingHeight: boreholeCalibration?.CasingHeight ?? null,
            concreteBlockHeight: boreholeCalibration?.ConcreteBlockHeight ?? null,
            holeDepth: boreholeCalibration?.HoleDepth ?? null,
            beforePiezoLevel: boreholeCalibration?.BeforePiezoLevel ?? null,
            afterPiezoLevel: boreholeCalibration?.AfterPiezoLevel ?? null,
            piezoHeight: boreholeCalibration?.PiezoHeight ?? null,
            availableDrawdown: boreholeCalibration?.AvailableDrawdown ?? null,
            pumpDepth: boreholeCalibration?.PumpDepth ?? null,
            latitude: boreholeCalibration?.Geo?.latitude ?? null,
            longitude: boreholeCalibration?.Geo?.longitude ?? null,
            elevation: boreholeCalibration?.Elevation ?? null,
            neighbouringBoreholes: boreholeCalibration?.NeighbouringBoreholes.map((n) => ({
                boreholeCode: n.BoreholeCode,
                time: moment.utc(n.Time, 'HH:mm'),
                waterLevel: n.WaterLevel,
            })) ?? [],
            stages: BoreholeCalibrationStages.reduce((current, stage) => {
                const boreholeCalibrationStage = boreholeCalibration?.BoreholeCalibrationStages[stage];
                current[stage] = {
                    canComplete: boreholeCalibrationStage?.CanComplete ?? true,
                    isCompleted: boreholeCalibrationStage?.IsCompleted ?? false,
                    stageEntries: stage === 4 ? BoreholeCalibrationFinalStageTimes.map(time => {
                        const entry = boreholeCalibrationStage?.StageEntries.find(x => x.Time === time);

                        return {
                            abstractionRate: !entry?.AbstractionRate ? null : entry.AbstractionRate,
                            groundLevel: !entry?.GroundLevel ? null : entry.GroundLevel,
                            time,
                        };
                    }) : BoreholeCalibrationTimes.map(time => {
                        const entry = boreholeCalibrationStage?.StageEntries.find(x => x.Time === time);
                        return {
                            abstractionRate: !entry?.AbstractionRate ? null : entry.AbstractionRate,
                            groundLevel: !entry?.GroundLevel ? null : entry.GroundLevel,
                            time,
                        };
                    }),
                };

                return current;
            }, {} as Record<BoreholeCalibrationStagesType, IBoreholeCalibrationStageFormValues>),
            recoveryEntries: boreholeCalibration?.RecoveryEntries.map((n) => ({
                abstractionRate: null,
                groundLevel: !n.GroundLevel ? null : n.GroundLevel,
                time: n.Time as BoreholeCalibrationTimesType,
            })) ?? BoreholeCalibrationTimes.map((time) => ({
                abstractionRate: null,
                groundLevel: null,
                time,
            })),
            note: boreholeCalibration?.Note ?? '',
            achieved: BoreholeCalibrationStages.reduce((current, stage) => {
                if (stage > 3) return current;

                const achieved = boreholeCalibration?.Achieved as Record<BoreholeCalibrationStagesType, IBoreholeCalibrationDrawdownAchieved | null> | null;
                current[stage] = {
                    groundLevel: achieved?.[stage]?.GroundLevel ?? null,
                    percentageOfDrawdown: achieved?.[stage]?.PercentageOfDrawdown ?? null,
                };

                return current;
            }, {} as Record<BoreholeCalibrationStagesType, IBoreholeCalibrationDrawdownAchievedFormValues>)
        };
    }

    public static formSchema = () => Yup.object<YupShape>({
        guid: Yup.string().required('Required'),
        date: Yup.date().nullable().moment().required('Required'),
        employeeName: Yup.string().required('Required'),
        employeeNumber: Yup.string().required('Required'),
        installedYield: Yup.number().nullable().required('Required'),
        casingDiameter: Yup.number().nullable().required('Required'),
        casingHeight: Yup.number().nullable().required('Required'),
        concreteBlockHeight: Yup.number().nullable().required('Required'),
        holeDepth: Yup.number().nullable().required('Required'),
        beforePiezoLevel: Yup.number().nullable().required('Required'),
        afterPiezoLevel: Yup.number().nullable().required('Required'),
        piezoHeight: Yup.number().nullable().required('Required'),
        availableDrawdown: Yup.number().nullable().required('Required'),
        pumpDepth: Yup.number().nullable().required('Required'),
        latitude: Yup.number().nullable().required('Required'),
        longitude: Yup.number().nullable().required('Required'),
        elevation: Yup.number().nullable().required('Required'),
        neighbouringBoreholes: Yup.array(
            Yup.object<YupNeighbourShape>({
                boreholeCode: Yup.string().required('Required'),
                time: Yup.date().nullable().moment().required('Required'),
                waterLevel: Yup.number().required('Required'),
            }),
        ),
        stages: Yup.object<YupStageRecordShape>({
            0: yupStageSchema,
            1: yupStageSchema,
            2: yupStageSchema,
            3: yupStageSchema,
            4: yupLastStageSchema,
        }),
        recoveryEntries: Yup.array(yupRecoveryStageEntrySchema),
        note: Yup.string(),
        achieved: Yup.object<YupStageRecordShape>({
            0: yupAchievedSchema,
            1: yupAchievedSchema,
            2: yupAchievedSchema,
            3: yupAchievedSchema,
            4: Yup.object().nullable().optional(),
        }),
    });

    public static getMaximumAchieved = (calibrationTest : IBoreholeCalibration) => {

        let maximumAchieved = 0;
        let maximumRate = 0;
        let totalPumpingTime1 = 0;
        let totalPumpingTime2 = 0;
        let totalPumpingTime3 = 0;
        let totalPumpingTime4 = 0;
        let totalPumpingTime5 = 0;
        let totalRecoveryTime = 0;
        let totalTime = 0;

        lodash.forEach(calibrationTest.BoreholeCalibrationStages, (x, i) => {
            x.StageEntries.forEach((y) => {
                if (y.GroundLevel > maximumAchieved) {
                    maximumAchieved = y.GroundLevel;
                }

                if (y.AbstractionRate > maximumRate) {
                    maximumRate = y.AbstractionRate;
                }

                if (y.Time && y.AbstractionRate) {
                    if (Number(i) === 0 && (y.Time > totalPumpingTime1)) {
                        totalPumpingTime1 = y.Time;
                    }
                    if (Number(i) === 1 && ((y.Time + totalPumpingTime1) > totalPumpingTime2)) {
                        const time = y.Time + totalPumpingTime1;
                        totalPumpingTime2 = totalTime = time;
                    }
                    if (Number(i) === 2 && ((y.Time + totalPumpingTime2) > totalPumpingTime2)) {
                        const time = y.Time + totalPumpingTime2;
                        totalPumpingTime3 = totalTime = time;
                    }
                    if (Number(i) === 3 && ((y.Time + totalPumpingTime3)  > totalPumpingTime4)) {
                        const time = y.Time + totalPumpingTime3;
                        totalPumpingTime4 = totalTime = time;
                    }
                    if (Number(i) === 4 && ((y.Time + totalPumpingTime4) > totalPumpingTime5)) {
                        const time = y.Time + totalPumpingTime4;
                        totalPumpingTime5 = totalTime = time;
                    }
                }
            });
        });

        let maximumRecoveryAchieved = maximumAchieved;
        if (calibrationTest.RecoveryEntries.length !== 0) {
            calibrationTest.RecoveryEntries.forEach((x) => {
                if (x.IsActive && x.GroundLevel < maximumRecoveryAchieved) {
                    maximumRecoveryAchieved = x.GroundLevel;
                    if (x.Time) {
                        totalRecoveryTime = x.Time;
                    }
                }
            });
        }

        const drawDownPercentage = calibrationTest.AvailableDrawdown && maximumAchieved && calibrationTest.BeforePiezoLevel ?
            Number(((maximumAchieved) / calibrationTest.AvailableDrawdown) * 100) : 0;
        const recoveryPercentage = maximumRecoveryAchieved && maximumAchieved && calibrationTest.BeforePiezoLevel && calibrationTest.PumpDepth ?
            Number(((calibrationTest.PumpDepth - maximumRecoveryAchieved) / (calibrationTest.PumpDepth - calibrationTest.BeforePiezoLevel)) * 100) : 0;

        return {
            maximumAchieved,
            maximumRate,
            totalPumpingTime1,
            totalPumpingTime2,
            totalPumpingTime3,
            totalPumpingTime4,
            totalPumpingTime5,
            totalRecoveryTime,
            maximumRecoveryAchieved,
            drawDownPercentage,
            recoveryPercentage,
            totalTime: totalTime + totalRecoveryTime,
        };
    };
}
