import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { IAuthState, IRootState, RootAction } from './@types/redux';
import { Dispatch, bindActionCreators } from 'redux';
import NavDrawer from './modules/root/NavDrawer';
import NavBar from './modules/root/NavBar';
import Routes from './modules/root/Routes';
import SnackbarNotifier from './modules/root/SnackbarNotifier';
import Login from './modules/Login';
import authFunctions from './store/auth/functions';
import * as Yup from 'yup';
import moment from 'moment';

interface IAppProps extends RouteComponentProps {
    auth : IAuthState;
}

interface IAppState {
    leftDrawerOpen : boolean;
    rightDrawerOpen : boolean;
}

class App extends React.Component<IAppProps, IAppState> {
    constructor(props : IAppProps) {
        super(props);

        this.state = {
            leftDrawerOpen: false,
            rightDrawerOpen: false,
        };
    }

    public componentDidMount() {
        authFunctions.setAuthState();

        Yup.addMethod(Yup.date, 'moment', () => {
            return Yup.date().transform((value : moment.Moment | null) => {
                if (Yup.date().isType(value)) return value;
          
                if (!value) return null;

                return moment(value).isValid() ? value.asUTC().toDate() : null;
            }).nullable();
        });
    }

    private toggleLeftDrawer = () => {
        this.setState({ leftDrawerOpen: !this.state.leftDrawerOpen });
    };

    private toggleRightDrawer = () => {
        this.setState({ rightDrawerOpen: !this.state.rightDrawerOpen });
    };

    public render() {        
        return (
            <div className={'fdc hfill mh0'}>
                <NavBar toggleLeftDrawer={this.toggleLeftDrawer} toggleRightDrawer={this.toggleRightDrawer} />
                <div className={'fdc hfill'}>
                    <NavDrawer key='leftmenu' toggleDrawer={this.toggleLeftDrawer} openLeft={this.state.leftDrawerOpen} openRight={false} /> {/* For Navigation */}
                    <NavDrawer key='rightmenu' toggleDrawer={this.toggleRightDrawer} openRight={this.state.rightDrawerOpen} openLeft={false} /> {/* For Logging out */}
                    {
                        this.props.auth.session && !this.props.auth.isLoggingIn ? (
                            <Routes />
                        ) : (
                            <Login />
                        )
                    }
                </div>
                <SnackbarNotifier />
            </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatcher : Dispatch<RootAction>) => bindActionCreators(
    { }, dispatcher);

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(App));
